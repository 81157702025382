<template>
  <div class="plugin-unit-chart">
    <div
      v-if="unit1"
      class="plugin-unit-chart__unit-layout"
    >
      <span class="plugin-unit-chart__unit">
        {{ unit1 }}
      </span>
    </div>
    <div class="plugin-unit-chart__chart">
      <slot/>
    </div>
    <div
      v-if="unit2"
      class="plugin-unit-chart__unit-layout"
    >
      <span class="plugin-unit-chart__unit plugin-unit-chart__unit--2">
        {{ unit2 }}
      </span>
    </div>
  </div>
</template>

<script>
  import Chart from 'chart.js'
  import { mapGetters } from 'vuex'

  export default {
    props: {
      unit: {
        type: [String, Array],
        required: false,
        default: '',
        validator(val) {
          return Array.isArray(val) ? val.length <= 2 : true
        },
      },
    },

    created() {
      Chart.defaults.global.defaultFontColor = this.colors['dark-grey']
      Chart.defaults.global.defaultFontSize = 12
      Chart.defaults.global.defaultFontStyle = 'normal'
      Chart.defaults.global.defaultFontFamily = 'Averta'
    },

    computed: {
      ...mapGetters('colors', [
        'colors',
      ]),
      unit1() {
        return Array.isArray(this.unit) ? this.unit?.[0] : this.unit || null
      },
      unit2() {
        return Array.isArray(this.unit) ? this.unit?.[1] : null
      },
    },
  }
</script>

<style lang="scss">
  $block: 'plugin-unit-chart';

  .#{$block} {
    display: flex;
    &__unit-layout {
      width: 1.2rem;
      position: relative;
      @include min-md {
        width: 3rem;
        height: 18rem;
      }
    }
    &__unit {
      display: block;
      color: color(dark-grey);
      font-size: 1.2rem;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 14rem;
      text-align: center;
      transform: translate(-50%, -50%) rotate(-90deg);
      cursor: default;
      &--2 {
        color: color('chart5');
      }
    }
    &__chart {
      flex-grow: 1;
    }
    canvas {
      max-width: 100%;
    }
  }
</style>
