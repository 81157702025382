<template>
  <div
    class="search-box nhsuk-header__search"
    data-testid="search-box"
  >
    <form
      class="search-box__form nhsuk-header__search-form"
      role="search"
      :aria-label="ariaLabel"
      @submit.prevent="onSubmit"
    >
      <screen-readers-only>
        <label
          :id="`label-${uuid}`"
          :for="`search-${uuid}`"
        >
          {{ $t('Search') }}
        </label>
      </screen-readers-only>

      <input
        :aria-labelledby="`label-${uuid}`"
        :id="`search-${uuid}`"
        class="search-box__input nhsuk-search__input"
        data-testid="search-box-input"
        placeholder="Search"
        type="search"
        v-model.trim="model"
      >
      <button
        aria-label="Search"
        class="search-box__submit nhsuk-search__submit"
        data-testid="search-box-submit"
        type="submit"
      >
        <inline-svg
          class="search-box__icon"
          role="presentation"
          src="icons/icon-search.svg"
        />
      </button>
    </form>
  </div>
</template>

<script>
  import ScreenReadersOnly from '@/components/screen-readers-only/ScreenReadersOnly'

  export default {
    components: {
      ScreenReadersOnly,
    },

    props: {
      ariaLabel: {
        type: String,
        default: '',
      },
      value: {
        type: String,
        default: '',
      },
    },

    data() {
      return {
        form: {
          page: 1,
          query: '',
        },
      }
    },

    methods: {
      onSubmit() {
        if (!this.model) return

        this.form.query = this.model

        this.$router.push({
          name: 'SearchResults',
          params: { ...this.form },
        })
      },
    },

    computed: {
      model: {
        get() {
          return this.value
        },
        set(newValue) {
          this.$emit('input', newValue)
        },
      },
    },
  }
</script>

<style lang="scss">
  $button-width: 40px;
  $input-height: 4rem;

  .search-box {
    width: 100%;
    height: fit-content;
    overflow: visible;
    @include min-lg {
      min-width: 6rem;
      width: auto;
      max-width: 30rem;
      height: $input-height;
    }
    &__form {
      width: 100%;
      height: fit-content;
      position: relative;
      padding: 0;
    }
    &__input {
      width: calc(100% - #{$button-width});
      height: $input-height;
      border-color: color(light-grey);
    }
    &__submit {
      width: $button-width;
      height: $input-height;
      border-left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      background: color(button-background) !important;
      &:hover {
        border-left: 0;
        border-color: color(light-grey);
        background: color(button-hover-background) !important;
        .search-box__icon {
          svg {
            fill: color(button-hover-text);
          }
        }
      }
    }
    &__icon {
      svg {
        width: 2.2rem;
        height: 2.2rem;
        fill: color(button-text);
      }
    }
  }
</style>
