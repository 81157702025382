<template>
  <tag
    ref="item"
    class="tabs-item"
    :data-testid="testId"
    v-bind="{ tag, ...tabProps }"
    @click.native="setActiveTab"
    @mouseleave.native="triggerAnimation('leave')"
    @mouseover.native="triggerAnimation('over')"
    @blur.native="triggerAnimation('leave')"
    @focus.native="triggerAnimation('over')"
  >
    <span
      class="tabs-item-name"
      ref="name"
    >
      {{ $t(item.name) }}
    </span>
  </tag>
</template>

<script>
  import Tag from '@/components/layout/tag/Tag'

  export default {
    components: {
      Tag,
    },

    props: {
      i: {
        type: Number,
        required: true,
      },
      item: {
        type: Object,
        required: true,
      },
      tag: {
        type: String,
        required: true,
      },
    },

    methods: {
      triggerAnimation(type) {
        this.$emit(`mouse${type}`, this.i)
      },
      setActiveTab() {
        if (this.isRouterLink) return this.$emit('tabChange', this.item)

        document.querySelectorAll('.tabs-item')
          .forEach(item => item.classList.remove('tabs-item--is-active'))

        this.$refs.item.classList.add('tabs-item--active')
        this.$emit('tabChange', this.item)
      },
    },

    computed: {
      isRouterLink() {
        return this.tag == 'router-link'
      },
      tabProps() {
        if (!this.isRouterLink) return ''
        if (this.item.path) {
          return { to: { path: this.item.path }, exact: true, exactActiveClass: 'tabs-item--is-active' }
        }
        return { to: { name: this.item.link }, exact: true, exactActiveClass: 'tabs-item--is-active' }
      },
      testId() {
        return `tabs-item-${this.item.name.toLowerCase()}`
      },
    },
  }
</script>

<style lang="scss">
  .tabs-item {
    height: 100%;
    padding: 0 1rem;
    position: relative;
    @include min-lg {
      padding: 0 1.5rem;
    }
    &-name {
      display: inline-block;
      margin: 0.5rem 0 0;
      color: color(text-primary);
      width: max-content;
      max-width: 90px;
    }
  }
</style>
