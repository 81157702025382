<template>
  <base-main
    appbar
    class="change-email"
    data-testid="auth-change-email"
    :loading="$options.loading">
      <container
        first
        white>
          <p
            data-testid="auth-change-email-message"
            v-html="statusMessage"
          />
          <base-button
            align-end
            data-testid="auth-change-email-back"
            :link="{ name: 'AuthLogin' }"
          >
            {{ $t('Go back to Login Page') }}
          </base-button>
      </container>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

  import {
    BaseButton,
    BaseMain,
  } from '@/components/base'
  import Container from '@/components/container/Container'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.auth.auth-change-email.meta.title'),
      }
    },

    components: {
      BaseButton,
      BaseMain,
      Container,
    },

    props: {
      token: {
        type: String,
        required: true,
      },
    },

    created() {
      this.onLoad()
    },

    data() {
      return {
        errorMessage: '',
      }
    },

    methods: {
      ...mapActions('auth', [
        'logout',
      ]),
      ...mapActions('user', [
        'changeEmail',
      ]),
      ...mapMutations('snackbars', [
        'addSnackbar',
      ]),
      onLoad() {
        this.changeEmail({ code: this.token })
        .then(this.logoutIfNecessary)
        .catch(err => {
          this.errorMessage = err || this.$t('Something went wrong. Please try again.')
          this.addSnackbar({ message: this.errorMessage })
        })
      },
      logoutIfNecessary() {
        return new Promise(resolve => {
          this.isCurrentUserAuthorized
            ? this.logout()
                .then(resolve)
            : resolve()
        })
      },
    },

    computed: {
      ...mapState('auth', [
        'isCurrentUserAuthorized',
      ]),
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      isSaving() {
        return this.getLoadingStatesForActions([
          'user/changeEmail',
        ])
      },
      statusMessage() {
        return this
          .$static
          .changeEmailInformation[this.errorMessage ? 'error' : 'success']
      },
    },

    static() {
      return {
        changeEmailInformation: {
          success: `Your email address has been successfully changed,
            you can login using the new email now.`,
          error: `The link you followed has expired. Please contact us for assistance at
            <a href="mailto:customersupport@changinghealth.com">customersupport@changinghealth.com</a>`,
        },
      }
    },

    loading: [
      'auth/logout',
      'user/changeEmail',
    ],
  }
</script>
