<template>
  <div class="plugin-line-chart">
    <line-chart
      :chartData="chart.data"
      :height="220"
      :options="chart.options"
    />
  </div>
</template>

<script>
  import { cloneDeep } from 'lodash'
  import moment from 'moment'
  import { mapState } from 'vuex'

  import LineChart from '@/components/charts/line-chart/LineChart'

  import getCssColorVariable from '@/helpers/getCssColorVariable'

  export default {
    components: {
      LineChart,
    },

    props: {
      annotationLabel: {
        type: String,
        required: false,
        default: '',
      },
      onClick: {
        type: Function,
        required: false,
        default: () => {},
      },
      chartData: {
        type: Object,
        required: true,
      },
      goal: {
        type: Number,
        required: false,
        default: 0,
      },
      goalPadding: {
        type: Number,
        required: false,
        default: 500,
      },
      suggestedMax: {
        type: Number,
        required: false,
        default: 1000,
      },
      suggestedMin: {
        type: Number,
        required: false,
        default: 0,
      },
      period: {
        type: String,
        required: false,
        default: '',
      },
    },

    created() {
      this.processChartData()
    },

    watch: {
      chartData() {
        this.processChartData()
      },
      pointBackgroundColor() {
        this.processChartData()
      },
    },

    data() {
      return {
        chart: {
          data: {},
          options: {
            animation: false,
            annotation: {
              annotations: [{
                borderColor: this.annotationLabel
                  ? getCssColorVariable('dark-primary')
                  : 'rgba(0,0,0,0)',
                borderWidth: 2,
                drawTime: 'afterDatasetsDraw',
                id: 'hline',
                label: {
                  backgroundColor: getCssColorVariable('_white'),
                  content: this.annotationLabel,
                  cornerRadius: 0,
                  enabled: true,
                  fontFamily: "'Averta', sans-serif",
                  fontSize: 12,
                  fontColor: getCssColorVariable('dark-primary'),
                },
                mode: 'horizontal',
                scaleID: 'y-axis-0',
                type: 'line',
                value: this.goal,
              }],
            },
            barRoundness: 1,
            hover: {
              onHover: (e, { length }) => {
                e.target.style.cursor = length ? 'pointer' : 'default'
              },
            },
            layout: {
              padding: {
                top: 20,
                bottom: 20,
                left: 0,
                right: 20,
              },
            },
            legend: {
              display: false,
            },
            maintainAspectRatio: false,
            responsive: true,
            scales: {
              xAxes: [{
                barThickness: 'flex',
                gridLines: false,
                ticks: {
                  fontFamily: "'Averta', sans-serif",
                  fontSize: 12,
                  fontStyle: 'normal',
                  fontColor: getCssColorVariable('dark-grey'),
                  maxRotation: 0,
                  padding: 20,
                },
              }],
              yAxes: [{
                gridLines: {
                  drawBorder: false,
                  drawTicks: false,
                  zeroLineColor: '#e5e5e5',
                },
                ticks: {
                  beginAtZero: false,
                  fontFamily: "'Averta', sans-serif",
                  fontSize: 12,
                  fontStyle: 'normal',
                  fontColor: getCssColorVariable('dark-grey'),
                  maxRotation: 0,
                  maxTicksLimit: 8,
                  padding: this.isMinMd ? 20 : 10,
                  suggestedMin: Math.min(this.goal, this.suggestedMin),
                  suggestedMax: Math.max(this.goal + this.goalPadding, this.suggestedMax),
                },
              }],
            },
            tooltips: false,
          },
        },
      }
    },

    methods: {
      processChartData() {
        const { formatStringByView } = this.$static

        this.chart.data = cloneDeep(this.chartData.data)
        this.chart.data.labels = this.chart.data.labels
          .map(item => moment(item).format(formatStringByView[this.period]))
        this.chart.options.onClick = (e, item) => {
          this.onClick(e, item)
        }

        if (!this.annotationLabel) return

        this.chart.options.annotation.annotations[0].label.yAdjust = -13
      },
    },

    computed: {
      ...mapState('layout', [
        'wW',
      ]),
      isMinMd() {
        return this.wW > 768
      },
      pointBackgroundColor() {
        return this.chartData?.data.datasets[0]?.pointBackgroundColor
      },
    },

    static() {
      return {
        formatStringByView: {
          week: 'ddd',
          month: 'DD',
          year: 'MMM',
        },
      }
    },
  }
</script>
