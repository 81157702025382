<template>
  <div class="plugin-bar-chart">
    <rounded-bar-chart
      :chartData="chart.data"
      :height="220"
      :options="chart.options"
    />
  </div>
</template>

<script>
  import { cloneDeep } from 'lodash'
  import moment from 'moment'
  import { mapState } from 'vuex'

  import RoundedBarChart from '@/components/charts/rounded-bar-chart/RoundedBarChart'

  import getCssColorVariable from '@/helpers/getCssColorVariable'

  export default {
    inheritAttrs: false,

    components: {
      RoundedBarChart,
    },

    props: {
      annotationLabel: {
        type: String,
        required: false,
        default: '',
      },
      onClick: {
        type: Function,
        required: false,
        default: () => {},
      },
      chartData: {
        type: Object,
        required: true,
      },
      goal: {
        type: Number,
        required: false,
        default: 0,
      },
      goalPadding: {
        type: Number,
        required: false,
        default: 500,
      },
      suggestedMax: {
        type: Number,
        required: false,
        default: 1000,
      },
      suggestedMin: {
        type: Number,
        required: false,
        default: 0,
      },
      period: {
        type: String,
        required: false,
        default: '',
      },
      selectedDatapointIndex: {
        type: Number,
        required: false,
        default: null,
      },
    },

    created() {
      this.processChartData()
    },

    watch: {
      chartData() {
        this.processChartData()
      },
      backgroundColor() {
        this.processChartData()
      },
    },

    data() {
      return {
        chart: {
          data: {},
          options: {
            animation: false,
            annotation: {
              annotations: [
                {
                  borderColor: this.annotationLabel
            ? getCssColorVariable('dark-primary')
            : 'rgba(0,0,0,0)',
                  borderWidth: 2,
                  drawTime: 'afterDatasetsDraw',
                  id: 'hline',
                  label: {
                    backgroundColor: getCssColorVariable('_white'),
                    content: this.annotationLabel,
                    cornerRadius: 0,
                    enabled: true,
                    fontFamily: "'Averta', sans-serif",
                    fontSize: 12,
                    fontColor: getCssColorVariable('dark-primary'),
                  },
                  mode: 'horizontal',
                  scaleID: 'y-axis-0',
                  type: this.period === 'year' ? '' : 'line',
                  value: this.goal,
                },
                {
                  type: 'line',
                  mode: 'horizontal',
                  scaleID: 'y-axis-0',
                  value: 0,
                  borderColor: getCssColorVariable('primary'),
                  borderWidth: 2,
                  label: {
                    backgroundColor: getCssColorVariable('_white'),
                    content: '',
                    fontFamily: "'Averta', sans-serif",
                    fontSize: 12,
                    fontColor: getCssColorVariable('primary'),
                    enabled: true,
                  },
                },
              ],
            },
            barRoundness: 1,
            hover: {
              onHover: (e, { length }) => {
                e.target.style.cursor = length ? 'pointer' : 'default'
              },
            },
            layout: {
              padding: {
                top: 20,
                bottom: 20,
                left: this.isMinMd ? 20 : 0,
                right: this.isMinMd ? 20 : 0,
              },
            },
            legend: {
              display: false,
            },
            maintainAspectRatio: false,
            responsive: true,
            scales: {
              xAxes: [{
                barThickness: 'flex',
                gridLines: false,
                maxBarThickness: 16,
                ticks: {
                  fontFamily: "'Averta', sans-serif",
                  fontSize: 12,
                  fontStyle: 'normal',
                  fontColor: getCssColorVariable('dark-grey'),
                  maxRotation: 0,
                  padding: 20,
                },
              }],
              yAxes: [{
                gridLines: {
                  drawBorder: false,
                  drawTicks: false,
                  zeroLineColor: '#e5e5e5',
                },
                minBarLength: 16,
                ticks: {
                  beginAtZero: true,
                  callback: val => {
                    const numVal = Number(val)

                    return numVal < 1000 ? numVal : `${numVal / 1000}k`
                  },
                  fontFamily: "'Averta', sans-serif",
                  fontSize: 12,
                  fontStyle: 'normal',
                  fontColor: getCssColorVariable('dark-grey'),
                  maxRotation: 0,
                  maxTicksLimit: 8,
                  padding: this.isMinMd ? 10 : 6,
                  suggestedMax: this.period === 'year'
                    ? this.suggestedMax * 10
                    : Math.max((this.goal || 0) * 1.2 + this.goalPadding, this.suggestedMax),
                  suggestedMin: this.suggestedMin,
                },
              }],
            },
            tooltips: false,
          },
        },
      }
    },

    methods: {
      processChartData() {
        const { formatStringByView } = this.$static

        this.chart.data = cloneDeep(this.chartData.data)
        this.chart.data.labels = this.chart.data.labels
          .map(item => moment(item).format(formatStringByView[this.period]))

        const firstValue = this.chart?.data?.datasets[0].data[this.selectedDatapointIndex]
        this.addAnnotationBySelectedValue(firstValue)
        this.chart.options.onClick = (e, item) => {
          if (item[0]) {
            const value = this.chart?.data?.datasets[0].data[item[0]._index]
            this.addAnnotationBySelectedValue(value)
          }
          this.onClick(e, item)
        }

        if (!this.annotationLabel) return

        this.chart.options.annotation.annotations[0].label.yAdjust = -13
      },
      addAnnotationBySelectedValue(value) {
        this.chart.options.annotation.annotations[1].value = value
        this.chart.options.annotation.annotations[1].label.content = value
        this.chart.options.annotation.annotations[1].label.yAdjust = -13
      },
    },

    computed: {
      ...mapState('layout', [
        'wW',
      ]),
      isMinMd() {
        return this.wW > 768
      },
      backgroundColor() {
        return this.chartData.data.datasets[0].backgroundColor
      },
    },

    static() {
      return {
        formatStringByView: {
          week: 'ddd',
          month: 'DD',
          year: 'MMM',
        },
      }
    },
  }
</script>

<style lang="scss">
  .plugin {
    &-bar-chart {
      width: 100%;
      padding: 2rem 0 0;
      position: relative;
      background-color: color(_white);
      & > div {
        width: 100%;
        max-height: 22rem;
        canvas {
          width: 100%;
        }
      }
    }
  }
</style>
