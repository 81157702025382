<script>
  import 'chartjs-plugin-annotation'
  import { Line, mixins } from 'vue-chartjs'

  import eventBus from '@/tools/event-bus'

  const { reactiveProp } = mixins

  export default {
    extends: Line,

    mixins: [
      reactiveProp,
    ],

    props: {
      chartData: {
        type: Object,
        required: false,
        default: () => ({}),
      },
      options: {
        type: Object,
        required: false,
        default: () => ({}),
      },
    },

    created() {
      eventBus.$on('lineChart/refreshChart', this.refreshChart)
    },

    beforeDestroy() {
      eventBus.$off('lineChart/refreshChart', this.refreshChart)
    },

    mounted() {
      this.refreshChart()
    },

    methods: {
      refreshChart() {
        if (this.$data._chart) this.$data._chart.destroy()

        this.renderChart(this.chartData, this.options)
      },
    },
  }
</script>
