<script>
  import { isObject } from 'lodash'

  export default {
    functional: true,

    props: {
      to: {
        type: [Object, String],
        required: false,
        default: null,
      },
    },

    render(h, { props, children, data, listeners }) {
      const isRouterLink = isObject(props.to)
      const tag = isRouterLink
        ? 'router-link'
        : 'a'
      const linkAttrs = tag === 'a'
        ? { href: props.to, target: '_blank' }
        : {}
      const testids = ['base-button']
        .concat(data.attrs?.['data-testid'], props.dataTestid)
        .filter(val => val)
        .join(' ')

      return h(tag, {
        ...data,
        staticClass: [data.staticClass, 'anchor'].join(' ').trim(),
        attrs: {
          ...linkAttrs,
          'data-testid': testids,
        },
        props: {
          to: props.to && isRouterLink ? props.to : null,
        },
        on: {
          ...listeners,
        },
      }, children)
    },
  }
</script>
