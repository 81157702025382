<template>
  <div
    class="reset-password-confirm"
    data-testid="auth-reset-password-confirm"
  >
    <loading-indicator
      v-if="isLoading"
    />

    <auth-wrapper
      v-else-if="isTokenValid"
      @onSubmit="onSubmit"
    >
      <template #error>
        <input-details
          v-if="customErrorMessage"
          id="reset-password-confirm-custom-error"
          class="login__custom-error"
          data-testid="auth-reset-password-confirm-error"
          :inputError="customErrorMessage"
        />
      </template>
      <template v-slot:title>
        {{ $t(`${$options.slug}.title`) }}
      </template>
      <template v-slot:subtitle>
        {{ $t(`${$options.slug}.subtitle`) }}
      </template>

      <validate-password
        :label="$t(`${$options.slug}.new-password`)"
        :name="'password'"
        :insideDescription="false"
        :outsideDescription="true"
        @validPassword="(password) => form.password = password"
      />

      <template #action>
        <base-button
          class="reset-password-confirm__reset"
          data-testid="auth-reset-password-confirm-submit"
          :isDisabled="isSaving"
          :isLoading="isSaving"
          :type="'submit'"
        >
          {{ $t(`${$options.slug}.submit`) }}
        </base-button>
      </template>
      <template #links>
        <router-link
          data-testid="auth-reset-password-confirm-back"
          :to="{ name: 'AuthLogin' }"
        >
          {{ $t(`${$options.slug}.back`) }}
        </router-link>
      </template>
    </auth-wrapper>

    <empty-view
      v-else
      data-testid="auth-reset-password-confirm-invalid-token"
    >
        <span v-html="$t(`${$options.slug}.error`)" />
        <a href="mailto:customersupport@changinghealth.com">
        customersupport@changinghealth.com</a>
    </empty-view>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'

  import { BaseButton } from '@/components/base'
  import EmptyView from '@/components/empty-view/EmptyView'
  import InputDetails from '@/components/forms/input-details/InputDetails'
  import ValidatePassword from '@/components/forms/validate-password/ValidatePassword'
  import LoadingIndicator from '@/components/loading-indicator'
  import AuthWrapper from '@/components/wrappers/AuthWrapper'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.auth.auth-reset-password-confirm.meta.title'),
      }
    },

    components: {
      AuthWrapper,
      BaseButton,
      EmptyView,
      InputDetails,
      ValidatePassword,
      LoadingIndicator,
    },

    props: {
      token: {
        type: String,
        required: true,
      },
    },

    created() {
      this.verifyResetPasswordToken(this.token)
        .then(() => this.isTokenValid = true)
        .catch(() => this.isTokenValid = false)
    },

    data() {
      return {
        customErrorMessage: null,
        form: {
          password: '',
        },
        isTokenValid: true,
      }
    },

    methods: {
      ...mapActions('auth', [
        'logout',
      ]),
      ...mapActions('dialog', [
        'openDialog',
      ]),
      ...mapActions('user', [
        'resetPassword',
        'verifyResetPasswordToken',
      ]),
      onSubmit(valid) {
        if (!valid || !this.form.password) {
          this.customErrorMessage = "Password don't meet requirements"
          return
        }

        const { password } = this.form
        const { token: code } = this

        this.resetPassword({ code, password })
        .then(this.logoutIfNecessary)
        .then(this.$nextTick)
        .then(() => this.openDialog({ component: 'AuthResetPasswordSuccess' }))
        .then(() => this.$router.replace({ name: 'AuthLogin' }))
        .catch(this.handleError)
      },
      logoutIfNecessary() {
        return new Promise(resolve => {
          this.isCurrentUserAuthorized
            ? this.logout()
                .then(resolve)
            : resolve()
        })
      },
      handleError(err) {
        this.customErrorMessage = err?.response?.data?.errors?.password
         || err?.message
         || 'Whoops! Something went wrong.'
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapState('auth', [
        'isCurrentUserAuthorized',
      ]),
      isSaving() {
        return this.getLoadingStatesForActions([
          'user/resetPassword',
        ])
      },
      isLoading() {
        return this.getLoadingStatesForActions([
          'user/verifyResetPasswordToken',
        ])
      },
    },

    validation: {
      password: {
        required: true,
        min: 9,
        max: 32,
      },
    },

    slug: 'views.auth.auth-reset-password-confirm',
  }
</script>

<style lang="scss">
  .reset-password-confirm {
    &__reset {
      min-width: 10rem;
      height: 3.2rem;
      margin: 1.8rem auto 1.8rem 0;
      font-size: 1.2rem;
      font-weight: 800;
      @include min-lg {
        min-width: 12rem;
        height: 4rem;
        padding: .2rem 3.5rem;
        font-size: 1.4rem;
      }
    }
    &__buttons {
      display: flex;
      flex-direction: column;
    }
  }
  .input-details__error {
    font-size: 1.2rem;
    @include min-lg {
      font-size: 1.4rem;
    }
  }
</style>
