<template>
  <auth-wrapper
    class="reset-password"
    data-testid="auth-reset-password"
    @onSubmit="onSubmit"
  >
    <template #error>
      <input-details
        v-if="customErrorMessage"
        id="reset-password-custom-error"
        class="login__custom-error"
        data-testid="auth-reset-password-error"
        :inputError="customErrorMessage"
      />
    </template>
    <template #title>
      {{ $t('Reset password') }}
    </template>
    <template #subtitle>
      Please confirm your email address so that we can send you a link to reset your password.
    </template>

    <input-group
      has-hidden-asterisk
      :autocomplete="'password'"
      :label="'Email address:'"
      :name="'email'"
      :type="'email'"
      :validation="$options.validation.email"
      v-model="form.email"
    />

    <template #action>
      <base-button
        class="reset-password__reset"
        data-testid="auth-reset-password-submit"
        :isDisabled="isSaving"
        :isLoading="isSaving"
        :type="'submit'"
      >
        {{ $t('Send link by email') }}
      </base-button>
    </template>
    <template #links>
      <router-link
        id="back-to-login-button"
        data-testid="auth-reset-password-back"
        :to="{ name: 'AuthLogin' }"
      >
        {{ $t('Back to login') }}
      </router-link>
    </template>
  </auth-wrapper>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex'

  import { BaseButton } from '@/components/base'
  import InputDetails from '@/components/forms/input-details/InputDetails'
  import InputGroup from '@/components/forms/input-group/InputGroup'
  import AuthWrapper from '@/components/wrappers/AuthWrapper'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.auth.auth-reset-password.meta.title'),
      }
    },

    components: {
      AuthWrapper,
      BaseButton,
      InputDetails,
      InputGroup,
    },

    data() {
      return {
        customErrorMessage: null,
        form: {
          email: '',
        },
      }
    },

    methods: {
      ...mapActions('dialog', [
        'openDialog',
      ]),
      ...mapActions('user', [
        'resetPassword',
      ]),
      ...mapMutations('snackbars', [
        'addSnackbar',
      ]),
      onSubmit(valid) {
        if (!valid) return

        this.resetPassword(this.form)
          .then(() => this.openDialog({ component: 'AuthForgottenPasswordSent' }))
          .then(() => this.$router.push({ name: 'AuthLogin' }))
          .catch(this.handleError)
      },
      handleError(err) {
        this.customErrorMessage = err?.response?.data?.errors?.email?.[0]
          || this.$t('Whoops! Something went wrong.')

        this.addSnackbar({ message: this.customErrorMessage })
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      isSaving() {
        return this.getLoadingStatesForActions([
          'user/resetPassword',
        ])
      },
    },

    slug: 'views.auth.auth-reset-password',

    validation: {
      email: {
        required: true,
        email: true,
      },
    },
  }
</script>

<style lang="scss">
  .reset-password {
    &__reset {
      min-width: 10rem;
      height: 3.2rem;
      margin: 1.8rem auto 1.8rem 0;
      font-size: 1.2rem;
      font-weight: 800;
      @include min-lg {
        min-width: 12rem;
        height: 4rem;
        padding: .2rem 3.5rem;
        font-size: 1.4rem;
      }
    }
    .auth__buttons {
      flex-direction: column;
      align-items: flex-start;
    }
    .auth__links {
      margin: 0 0 1.8rem;
    }
  }
  .input-details__error {
    font-size: 1.2rem;
    @include min-lg {
      font-size: 1.4rem;
    }
  }
</style>
