<template>
  <tabs
    data-testid="header-tabs"
    :ariaLabel="$t('Site')"
    :items="items"
    @tabChange="tabChange"
  />
</template>
<script>
  import Tabs from '@/components/tabs'

  export default {
    components: {
      Tabs,
    },
    props: {
      items: {
        type: Array,
        default: () => [],
      },
    },
    methods: {
      tabChange(to) {
        this.$mam.event('Navigation click', {
          section: to.name,
        })
      },
    },
  }
</script>
