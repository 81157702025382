<template>
  <nav
    class="tabs"
    data-testid="tabs"
    :aria-label="ariaLabel"
  >
    <div
      class="tabs-container"
      ref="tabs"
    >
      <tabs-item
        v-for="(item, i) in items"
        :ref="`item-${i}`"
        :key="i"
        :class="{ 'tabs-item--is-active': isTabItemActive(i) }"
        v-bind="{ i, item, tag }"
        @mouseleave="moveIndicator"
        @mouseover="onMouseover"
        @tabChange="onTabChange"
      />

      <div
        ref="indicator"
        class="tabs-indicator"
      />
    </div>
  </nav>
</template>

<script>
  import gsap from 'gsap'

  import EventBus from '@/tools/event-bus'

  import TabsItem from './TabsItem'

  export default {
    name: 'Tabs',

    components: {
      TabsItem,
    },

    props: {
      ariaLabel: {
        type: String,
        required: false,
        default: '',
      },
      items: {
        type: Array,
        required: true,
      },
      tag: {
        type: String,
        required: false,
        default: 'router-link',
      },
    },

    mounted() {
      this.moveIndicator({ hasTransition: false })
      window.addEventListener('resize', this.moveIndicator)
      EventBus.$on('tabs/moveIndicator', () => {
        this.moveIndicator({ hasTransition: false })
      })
    },

    beforeDestroy() {
      window.removeEventListener('resize', this.moveIndicator)
    },

    watch: {
      $route() {
        this.$nextTick()
          .then(this.moveIndicator)
      },
    },

    methods: {
      onMouseover(i) {
        this.moveIndicator({ i })
      },
      onTabChange(item) {
        this.$emit('tabChange', item)
      },
      isTabItemActive(i) {
        return this.tag == 'button' && i == this.currentTab
      },
      moveIndicator({ hasTransition = true, i }) {
        const { width, x } = this.getItemDimensions(i)
        const duration = hasTransition ? 0.25 : 0
        const properties = x <= 0 ? { autoAlpha: 0 } : { width, x, autoAlpha: 1 }

        gsap.to(this.$refs.indicator, duration, properties)
      },
      getItemDimensions(i) {
        const { $refs } = this
        const el = i === undefined
                    ? $refs.tabs.querySelector('.tabs-item--is-active .tabs-item-name')
                    : $refs[`item-${i}`][0].$refs.name
        const tabsX = $refs.tabs?.getBoundingClientRect().x
        const { x = 0, width = 0 } = el?.getBoundingClientRect() || {}

        return { width, x: x - tabsX }
      },
    },
  }
</script>

<style lang="scss">
  .tabs {
    background-color: color(_white);
    &-container {
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
    }
    &-indicator {
      position: absolute;
      bottom: -0.1rem;
      left: 0;
      height: 0.2rem;
      background-color: color(primary);
      @include min-lg {
        bottom: -0.6rem;
        height: 0.3rem;
      }
    }
  }
</style>
