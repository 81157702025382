const properties = [
  'connection', 'cookieEnabled', 'deviceMemory', 'doNotTrack', 'hardwareConcurrency',
  'language', 'languages', 'onLine', 'platform', 'serviceWorker', 'userAgent', 'vendor',
]

const getDiagnosticData = () => properties.reduce((acc, curr) => {
  if (!window.navigator[curr]) return acc

  switch (curr) {
    case 'connection': {
      acc.connection = getConnectionDiagnostic()
      break
    }
    case 'serviceWorker': {
      acc.serviceWorker = getServiceWorkerDiagnostic()
      break
    }
    default: {
      acc[curr] = window.navigator[curr]
    }
  }

  return acc
}, {})

const getConnectionDiagnostic = () => {
  const { downlink, effectiveType, rtt, saveData } = window.navigator.connection

  return { downlink, effectiveType, rtt, saveData }
}

const getServiceWorkerDiagnostic = () => {
  const { controller } = window.navigator.serviceWorker
  const serviceWorker = controller
    ? { scriptURL: controller.scriptURL, state: controller.state }
    : null

  return serviceWorker
}

export default getDiagnosticData
