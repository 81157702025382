<template>
  <div
    class="two-factor"
    data-testid="auth-2-fa"
  >
    <loading-indicator
      v-if="isLoading"
    />

    <auth-wrapper
      class="two-factor__form"
      @onSubmit="onSubmit"
      v-else
    >
      <template #error>
        <input-details
          v-if="customErrorMessage"
          class="login__custom-error"
          :inputError="customErrorMessage"
        />
      </template>
      <template #title>
        <span v-html="i18n('title')" />
      </template>

      <input-group
        has-hidden-asterisk
        :label="i18n('code-label')"
        :name="'code'"
        :type="'text'"
        :validation="$options.validation.code"
        v-model="form.verificationCode"
      />

      <base-checkbox
        class="two-factor__remeber-device"
        :label="i18n('remember-label')"
        :name="'remeber-device'"
        v-model="form.remeberDevice"
      />

      <div class="two-factor__buttons">
        <base-button
          class="two-factor__reset"
          data-testid="auth-2-fa-submit"
          :isLoading="isSaving"
          :type="'submit'"
        >
          {{ i18n('cta-button-text') }}
        </base-button>

        <div class="auth__links">
          <button
            id="send-two-fa-again"
            class="two-factor__send-code-again"
            data-testid="auth-2-fa-send-code-again"
            type="button"
            @click="requestCode"
          >
            {{ i18n('send-again-text') }}
          </button>
        </div>
      </div>
    </auth-wrapper>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import {
    BaseButton,
    BaseCheckbox,
  } from '@/components/base'
  import InputDetails from '@/components/forms/input-details/InputDetails'
  import InputGroup from '@/components/forms/input-group/InputGroup'
  import LoadingIndicator from '@/components/loading-indicator'
  import AuthWrapper from '@/components/wrappers/AuthWrapper'

  import forceAnnounce from '@/mixins/forceAnnounce'
  import i18n from '@/mixins/i18n'
  import redirectToApplication from '@/mixins/redirectToApplication'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.auth.auth-2fa.meta.title'),
      }
    },

    components: {
      AuthWrapper,
      BaseButton,
      BaseCheckbox,
      InputDetails,
      InputGroup,
      LoadingIndicator,
    },

    mixins: [
      forceAnnounce,
      i18n,
      redirectToApplication,
    ],

    props: {
      email: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        customErrorMessage: '',
        form: {
          verificationCode: '',
          remeberDevice: false,
        },
      }
    },

    methods: {
      ...mapActions('auth', [
        'checkCode',
        'fetchCurrentUser',
        'requestCode',
      ]),
      onSubmit(isValid) {
        if (!isValid || this.isLoading) return

        const {
          verificationCode: code,
          remeberDevice: remember,
        } = this.form
        const { email } = this

        this.checkCode({ code, remember, email })
          .then(this.fetchCurrentUser)
          .then(() => {
            this.redirectToApplication()
            this.forceAnnounce(this.$t('Saved'))
          })
          .catch(error => {
            this.customErrorMessage = error?.response?.data?.error
              || 'Whoops! Provided code isn\'t correct.'
          })
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      isLoading() {
        return this.getLoadingStatesForActions([
          'auth/fetchCurrentUser',
          'auth/requestCode',
        ])
      },
      isSaving() {
        return this.getLoadingStatesForActions([
          'auth/checkCode',
        ])
      },
    },

    validation: {
      code: {
        required: true,
      },
    },

    slug: 'views.auth.auth-2fa',
  }
</script>

<style lang="scss">
  .two-factor {
    &__reset {
      min-width: 10rem;
      height: 3.2rem;
      margin: 1.8rem auto 1.8rem 0;
      font-size: 1.2rem;
      font-weight: 800;
      @include min-lg {
        min-width: 12rem;
        height: 4rem;
        padding: .2rem 3.5rem;
        font-size: 1.4rem;
      }
    }
    &__buttons {
      display: flex;
      flex-direction: column;
    }
    &__remeber-device {
      margin: 1rem 0 0 0;
    }
    &__send-code-again {
      text-decoration: underline;
      line-height: 1.15;
      font-weight: 600;
      color: color(dark-grey);
    }
    .auth__links {
      margin-bottom: 1.8rem;
    }
    .input-details__error {
      font-size: 1.2rem;
      @include min-lg {
        font-size: 1.4rem;
      }
    }
  }
</style>
