<template>
  <button
    v-if="navigationDrawer.isValid"
    class="header-drawer"
    aria-controls="navigation-drawer"
    :aria-label="!!hasOtherPrograms ? 'Toggle programmes drawer' : ''"
    data-testid="header-drawer"
    :title="!!hasOtherPrograms ? 'Toggle programmes drawer' : ''"
    type="button"
    :aria-expanded="navigationDrawer.isOpen.toString()"
    :disabled="!navigationDrawer.isValid"
    @click.stop.prevent="toggle"
    @keydown.space.stop.prevent="toggle"
    @keydown.enter.stop.prevent="toggle"
  >
    <img
      class="header-drawer__logo"
      alt=""
      :src="getCurrentProgramLogo"
    >
  </button>
  <img
    v-else
    class="header-drawer__logo"
    alt=""
    :src="getCurrentProgramLogo"
  >
</template>

<script>
  import { mapGetters, mapMutations, mapState } from 'vuex'

  export default {
    methods: {
      ...mapMutations('layout', [
        'set',
      ]),
      toggle({ type }) {
        this.set({
          state: 'navigationDrawer',
          value: {
            isOpen: !this.navigationDrawer.isOpen,
            trigger: type,
            isValid: this.navigationDrawer.isValid,
          },
        })
      },
    },

    computed: {
      ...mapGetters('program', [
        'getCurrentProgramLogo',
      ]),
      ...mapState('layout', [
        'navigationDrawer',
      ]),
      allPrograms() {
        return this.package?.programs || []
      },
      hasOtherPrograms() {
        return this.allPrograms.length
      },
    },
  }
</script>

<style lang="scss">
  .header-drawer {
    width: fit-content;
    height: 3.5rem;
    padding: 0;
    border-radius: 0.2rem;
    &__logo {
      height: 3.5rem;
      max-height: 30px;
      object-fit: contain;
      width: auto;
      // @include min-lg {
      //   width: 100%;
      //   height: 100%;
      // }
    }
    &:disabled {
      cursor: inherit;
    }
  }
</style>
