<template>
  <button
    class="header-menu"
    aria-label="Open dropdown menu"
    data-testid="header-menu"
    title="Open dropdown menu"
    type="button"
    :aria-controls="id"
    :aria-expanded="menu.isOpen.toString()"
    @click.stop.prevent="toggle"
    @keydown.space.stop.prevent="toggle"
    @keydown.enter.stop.prevent="toggle"
  >
    <template
      v-if="isDesktop"
    >
      {{ currentUserFullName }}
      <inline-svg
        role="presentation"
        src="icons/icon-cheveron-down.svg"
      />
    </template>

    <img
      v-else
      class="header-menu__avatar"
      alt=""
      :src="getCurrentUserAvatar"
    >
  </button>
</template>

<script>
  import { mapGetters, mapMutations, mapState } from 'vuex'

  export default {
    props: {
      isDesktop: {
        type: Boolean,
        required: true,
      },
      id: {
        type: String,
        required: true,
      },
    },

    methods: {
      ...mapMutations('layout', [
        'set',
      ]),
      toggle({ type }) {
        this.set({
          state: 'menu',
          value: {
            isOpen: !this.menu.isOpen,
            trigger: type,
          },
        })
      },
    },

    computed: {
      ...mapGetters('user', [
        'currentUserFullName',
        'getCurrentUserAvatar',
      ]),
      ...mapState('layout', [
        'menu',
      ]),
    },
  }
</script>

<style lang="scss">
  .header-menu {
    display: flex;
    align-items: center;
    padding: 0.3rem;
    color: color(text-primary);
    &__avatar {
      width: 3.5rem;
      height: 3.5rem;
      border: 2px solid color(primary);
      border-radius: 50%;
      object-fit: cover;
      @include min-lg {
        margin: 0 1rem 0 0;
      }
    }
    svg {
      width: 2rem;
      height: 2rem;
      margin: 0.3rem 0 0;
      fill: color(primary);
    }
  }
</style>
