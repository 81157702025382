const useFormMethods = (varName = 'formMethods') => ({
  data() {
    return {
      [varName]: {},
    }
  },

  methods: {
    onFormInitialize(formMethods) {
      this[varName] = formMethods
    },
  },
})

export default useFormMethods
