<template>
  <base-main
    default
    class="support-view"
    data-testid="support"
  >
    <div class="support-view__wrapper">
      <support-simple-tile name="support" />
    </div>
  </base-main>
</template>

<script>

  import BaseMain from '@/components/base/BaseMain'
  import SupportSimpleTile from '@/components/support/SupportSimpleTile'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.support.meta.title'),
      }
    },

    components: {
      BaseMain,
      SupportSimpleTile,
    },
  }
</script>
