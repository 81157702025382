<template>
  <base-main
    class="coaching-plugin-content-article"
    :loading="$options.loading"
  >
    <div class="coaching-plugin-content-article__wrapper">
      <base-article
        v-if="article"
        :key="article.id"
        v-bind="{ article }"
      />
    </div>
  </base-main>
</template>

<script>
  import { mapActions, mapState } from 'vuex'

  import {
    BaseArticle,
    BaseMain,
  } from '@/components/base'

  export default {
    metaInfo() {
      const { title } = this.article || {}

      return {
        title: [
          this.$t('views.plugins.coaching-plugin.coaching-plugin-content-article.meta.title'),
          title,
        ].filter(Boolean).join(' - '),
      }
    },

    components: {
      BaseArticle,
      BaseMain,
    },

    props: {
      id: {
        type: [Number, String],
        required: true,
      },
    },

    created() {
      this.fetchArticle(this.id)
    },

    methods: {
      ...mapActions('article', [
        'fetchArticle',
      ]),
    },

    computed: {
      ...mapState('article', [
        'article',
      ]),
    },

    loading: [
      'article/fetchArticle',
    ],
  }
</script>

<style lang="scss">
  .coaching-plugin-content-article {
    &__wrapper {
      height: 100%;
      padding: 0;
      @include view-wrapper(63rem);
    }
  }
</style>
