<template>
  <header
    v-if="isDesktop"
    ref="header"
    class="header"
    :class="{
      'header--has-search-only': hasSearchOnly,
    }"
    data-testid="header"
  >
    <div class="header__container header__container--desktop">
      <div class="header__items">
        <div class="header__item header__programs">
          <base-header-navigation-drawer-button/>

          <div class="header__program">
            {{ getCurrentProgramName }}
          </div>
        </div>

        <base-header-tabs
          :items="navItems"
          class="header__item header__item--center"
        />

        <div class="header__item header__item--end header__actions">
          <search-box
            data-testid="header-search"
            :ariaLabel="$t('Site')"
            v-model="form.search"
          />

          <div
            class="header__actions-profile"
            :class="{'header__actions-profile-secondary-logo': currentProgramSecondaryLogo}">
            <router-link
              aria-label="My Profile"
              class="header__user"
              data-testid="header-profile"
              :to="{ name: 'Profile' }"
            >
              <img
                alt=""
                class="header__avatar"
                :src="getCurrentUserAvatar"
              >
            </router-link>

            <div class="header__menu-container">
              <h3>{{ currentUserFullName }}</h3>

              <div>
                <base-anchor
                  data-testid="menu-profile"
                  class="header__menu-container-item"
                  :to="{ name: 'Profile' }"
                >
                  {{ $t('My profile') }}
                </base-anchor>

                <button
                  data-testid="menu-logout"
                  class="header__menu-container-item"
                  @click="handleLogout"
                >
                  {{ $t('Logout') }}
                </button>
              </div>
            </div>

            <img
              v-if="currentProgramSecondaryLogo"
              alt=""
              class="header__secondary-logo"
              :src="currentProgramSecondaryLogo"
            >
          </div>

        </div>
      </div>
      <base-header-view/>
    </div>
  </header>
  <header
    v-else-if="!showBurgerMenu"
    ref="header"
    class="header"
    :class="{
      'header--has-search-only': hasSearchOnly,
    }"
    data-testid="header"
  >
    <div class="header__container header__container--mobile">

      <div class="header__items">
        <base-header-navigation-drawer-button/>

        <base-header-tabs :items="navItems"/>

        <button
          aria-label="Search"
          class="search-box__btn search-box__submit nhsuk-search__submit"
          :class="{
            'is-active': showInput,
          }"
          data-testid="search-box-submit"
          type="button"
          @click="showInput = !showInput"
        >
          <inline-svg
            class="search-box__icon"
            role="presentation"
            src="icons/icon-search.svg"
          />
        </button>

        <div class="header__menu-container">
          <base-header-menu-button
            v-bind="{ isDesktop }"
            :id="`mobile_${uuid}`"
          />

          <base-menu
            :id="`mobile_${uuid}`"
          />
        </div>
      </div>
      <div
        v-if="showInput"
        ref="search"
        class="header__search"
      >
        <search-box
          data-testid="header-search"
          v-model="form.search"
        />
      </div>
    </div>
  </header>
  <header
    v-else
    ref="header"
    class="header nhsuk-header nhsuk-header--white"
    data-testid="header"
  >
    <div class="nhsuk-header__container header__container header__container--mobile">
      <div
        class="nhsuk-header__content"
        id="content-header">
        <base-header-navigation-drawer-button/>

        <div class="nhsuk-header__content-right">
          <div class="nhsuk-header__menu">
            <button
              data-testid="toggle-menu"
              class="nhsuk-header__menu-toggle"
              aria-controls="header-navigation"
              aria-expanded="false"
              @click="showMenuOptions=!showMenuOptions">
              Menu
            </button>
          </div>
          <button
            aria-label="Search"
            class="search-box__btn search-box__submit nhsuk-search__submit"
            :class="{
              'is-active': showInput,
            }"
            data-testid="search-box-submit"
            type="button"
            @click="showInput = !showInput"
          >
            <inline-svg
              class="search-box__icon"
              role="presentation"
              src="icons/icon-search.svg"
            />
          </button>
        </div>
      </div>

      <!-- PROFILE ?? -->
      <div
        v-if="showInput"
        ref="search"
        class="header__search"
      >
        <search-box
          data-testid="header-search"
          v-model="form.search"
        />
      </div>
    </div>
    <nav
      v-if="showMenuOptions"
      data-testid="header-navigation"
      class="nhsuk-header__navigation"
      id="header-navigation"
      role="navigation"
      aria-label="Primary navigation"
      aria-labelledby="label-navigation"
      :class="{
        'js-show': showMenuOptions
      }">
      <div class="nhsuk-width-container">
        <div class="nhsuk-width-container">
          <p class="nhsuk-header__navigation-title"><span id="label-navigation">Menu</span>
            <button
              data-testid="close-menu"
              class="nhsuk-header__navigation-close"
              @click="showMenuOptions=false">
              <inline-svg
                class="nhs-icon-close"
                role="presentation"
                src="icons/nhs-icon-close.svg"
              />
              <span class="nhsuk-u-visually-hidden">Close menu</span>
            </button>
          </p>
          <ul class="nhsuk-header__navigation-list">
            <li
              v-for="menuItem in menuItems"
              :key="menuItem.name"
              class="nhsuk-header__navigation-item nhsuk-header__navigation-item--for-mobile">
              <button
                :style="{ width: '100%', padding: 0 }"
                :dataTestid="menuItem.name"
                @click="showMenuOptions=false"
              >
                <router-link
                  class="nhsuk-header__navigation-link"
                  :to="routerLinkTo(menuItem)">
                    {{ menuItem.name }}
                  <inline-svg
                    class="nhs-icon-close"
                    role="presentation"
                    src="icons/nhs-icon-right-arrow.svg"
                  />
                </router-link>
              </button>
            </li>
            <li>
              <button
                data-testid="logout"
                class="nhsuk-header__navigation-link"
                @click="handleLogout"
              >
                {{ $t('Logout') }}
                <inline-svg
                  class="nhs-icon-close"
                  role="presentation"
                  src="icons/nhs-icon-right-arrow.svg"
                />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
  import gsap from 'gsap'

  import { mapActions, mapGetters, mapState } from 'vuex'

  import { BaseAnchor } from '@/components/base'
  import BaseMenu from '@/components/base/base-menu/BaseMenu'
  import { SearchBox } from '@/components/search'

  import BaseHeaderMenuButton from './BaseHeaderMenuButton'
  import BaseHeaderNavigationDrawerButton from './BaseHeaderNavigationDrawerButton'
  import BaseHeaderTabs from './BaseHeaderTabs'
  import BaseHeaderView from './BaseHeaderView'

  const HEADER_HEIGHT_MOBILE = 182

  export default {
    components: {
      BaseHeaderMenuButton,
      BaseHeaderNavigationDrawerButton,
      BaseHeaderTabs,
      BaseHeaderView,
      BaseMenu,
      SearchBox,
      BaseAnchor,
    },

    mounted() {
      this.toggleListener('add')
      this.showBurgerMenu = this.getNavExtraItems && this.getNavExtraItems.length > 0
    },

    beforeDestroy() {
      this.toggleListener('remove')
    },

    watch: {
      hasSearchOnly(newV, oldV) {
        if (newV && !oldV) {
          this.animateSearch()
        }
      },
    },

    data() {
      return {
        form: {
          search: '',
        },
        hasSearchOnly: false,
        showInput: false,
        showMenuOptions: false,
        showBurgerMenu: false,
      }
    },

    methods: {
      ...mapActions('auth', [
        'logout',
      ]),
      toggleListener(action) {
        window[`${action}EventListener`]('scroll', this.toggleHasSearchOnly)
      },
      toggleHasSearchOnly() {
        if (this.isDesktop) return

        this.hasSearchOnly = window.pageYOffset > HEADER_HEIGHT_MOBILE
      },
      animateSearch() {
        gsap.fromTo(
          this.$refs.search,
          0.2,
          { autoAlpha: 0 },
          { autoAlpha: 1, clearProps: 'all', immediateRender: false },
        )
      },
      handleLogout() {
        this.showMenuOptions = false
        this.logout()
          .then(this.$nextTick)
          .then(() => this.$router.push({ name: 'AuthLoggedOut' }))
      },
      routerLinkTo(menuItem) {
        return menuItem.link ? { name: menuItem.link } : menuItem.path
      },
    },

    computed: {
      ...mapGetters('program', [
        'currentProgramSecondaryLogo',
        'getCurrentProgramName',
        'getNavExtraItems',
      ]),
      ...mapGetters('user', [
        'currentUserFullName',
        'getCurrentUserAvatar',
      ]),
      ...mapState('layout', [
        'wW',
      ]),
      isDesktop() {
        return this.wW >= 990
      },

      defaultMenuItems() {
        return [
          {
            name: 'Home',
            link: 'Home',
          },
          {
            name: 'Learn',
            link: 'Learn',
          },
          {
            name: 'Tools',
            link: 'Tools',
          },
        ]
      },

      navItems() {
        return [
          ...this.defaultMenuItems,
          ...this.getNavExtraItems,
        ]
      },

      menuItems() {
        return [
          ...this.defaultMenuItems,
          ...this.getNavExtraItems,
          {
            name: 'My profile',
            link: 'Profile',
          },
        ]
      },
    },
  }
</script>

<style lang="scss">
  .header {
    z-index: z-index(header);
    @include min-lg {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
    }
    .header__search {
      display: flex;
    }
    &--has-search-only {
      height: $header_height;
      .header__container--mobile {
        .header__search {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          margin: 0;
          padding: 1rem 1.5rem;
          box-shadow: box-shadow(default);
          z-index: z-index(header);
        }
      }
    }
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: $header-stripe_height;
      background-color: color(primary);
      @include min-lg {
        height: $header-stripe_height--lg;
      }
    }
    &__container {
      background-color: color(_white);
      &--desktop {
        display: none;
        @include min-lg {
          display: block;
        }
      }
      &--mobile {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        margin: 0 0 1px;
        padding: 1rem 1.5rem;
        box-shadow: box-shadow(default);
        @include min-lg {
          display: none;
        }

        .search-box__btn {
          border-radius: 4px;
          &:not(:focus).is-active {
            filter: grayscale(50%);
          }
        }
      }
    }
    &__items {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: $header-items_height;
      @include min-lg {
        height: $header-items_height--lg;
        padding: 0 5.5rem;
      }
    }
    &__item {
      display: flex;
      align-items: center;
      flex: 1;
      &:nth-child(2) {
        flex: 0.5;
      }
      &--center {
        justify-content: center;
      }
      &--end {
        justify-content: flex-end;
      }
    }
    &__program {
      justify-content: space-between;
      align-items: center;
      color: color(primary-text);
      font-size: 1.8rem;
      font-weight: 800;
      margin: 0 0 0 1rem;
      @include min-lg {
        display: block;
        font-size: 1.8rem;
      }
    }
    &__actions {
      font-size: 1.2rem;
      font-weight: 600;
    }
    &__search {
      margin: 2.5rem 0;
      background-color: color(_white);
      z-index: 1;
      width: 100%;
    }
    &__actions-profile {
      display: flex;
      align-items: center;
      margin-left: 1.5rem;
      max-width: 250px;
      min-width: 150px;
      justify-content: flex-end;
      padding: 0.5rem 0;

      .header__menu-container {
        min-width: 110px;
      }

      &.header__actions-profile-secondary-logo {
        min-width: 210px;
      }
    }
    &__user {
      display: flex;
      align-items: center;
      padding: 0.5rem;
      svg {
        width: 2rem;
        height: 2rem;
        margin: 0.3rem 0 0;
        fill: color(primary);
      }
    }
    &__avatar {
      width: 3.5rem;
      height: 3.5rem;
      border: 2px solid color(primary);
      border-radius: 50%;
      object-fit: cover;
    }
    &__menu-container {
      position: relative;
      h3 {
        margin: 0;
      }
      &-item {
        &:first-child {
          margin-right: 0.5rem;
        }
        font-size: 1.05rem;
        cursor: pointer;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &__secondary-logo {
      width: 5.5rem;
      height: 3.5rem;
      object-fit: contain;
      margin-left: 1rem;
      @include min-lg {
        width: 6rem;
        height: 4rem;
      }
    }
  }
  .nhsuk-header {
    &__menu button {
      background-color: color(button-background) ;
      color: color(button-text) !important;
      border-color: color(button-background) !important;
      right: 0;
      &:focus {
        color: color(_black)!important;
      }
    }
    &__content {
      width: 100%;
      display: flex;
      justify-content: space-between;

      &-right {
        display: flex;
        width: 100%;
        justify-content: flex-end;

        .search-box__btn {
          margin-left: 1rem;
        }
      }
    }
    &__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0px;
    }
    &__navigation-link {
      font-size: small;
      color: color(primary) !important;
      font-weight: 400;
      text-decoration: none;
      padding: 12px 16px;
      width: 100%;
      text-align: initial;
    }
  }
</style>
