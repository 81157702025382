<template>
  <base-main
    class="auth"
    data-testid="auth-wrapper"
    v-bind="{ loading }"
  >
    <div class="auth__content">

      <div class="auth__background">
        <img
          alt=""
          class="auth__background-image"
          :src="requireImage('login_bg_2.png')"
        >
      </div>

      <div class="auth__tile-wrapper">
        <support-simple-tile
          class="auth__support"
          name="auth-support">
          <template #header>
            <div
              class="auth__error"
              data-testid="auth-wrapper-error"
            >
              <slot name="error" />
            </div>

            <img
              :src="requireImage('logo-ch.png')"
              class="auth__logo"
              alt=""
            >
          </template>
        </support-simple-tile>
      </div>
    </div>
  </base-main>
</template>
<script>
  import { mapGetters } from 'vuex'

  import BaseMain from '@/components/base/BaseMain'
  import SupportSimpleTile from '@/components/support/SupportSimpleTile'

  import goBack from '@/mixins/goBack'
  import requireImage from '@/mixins/requireImage'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.support.meta.title'),
      }
    },

    components: {
      BaseMain,
      SupportSimpleTile,
    },

    mixins: [
      goBack, // TODO ?
      requireImage,
    ],

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      loading() {
        const loadings = [
          'program/fetchProgram',
        ]
        const progressLoadings = [
          'articles/changeProgress',
          'program/fetchNextSection',
        ]
        return this.isSurveyWithTrackProgress
          ? loadings
          : [
            ...loadings,
            ...progressLoadings,
          ]
      },
    },
  }
</script>

<style lang="scss">
  .auth__support .support-view__bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .support-view__buttons {
      display: flex;
      flex-direction: row;
    }
  }
</style>
