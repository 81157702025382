<template>
  <div
    class="nhsuk-radios__item base-radio"
    :class="{
      'base-radio--is-multi': isMulti,
    }"
    :data-testid="`${dataTestid} ${getTestId()}`"
  >
    <input
      class="nhsuk-radios__input base-radio__input"
      type="radio"
      :aria-describedby="describedBy || undefined"
      :aria-invalid="ariaInvalid"
      :aria-required="isRequired"
      :checked="isChecked.toString()"
      :data-testid="getTestId('input')"
      :id="inputId"
      :name="name"
      :value="inputValue"
      v-bind="{ tabindex }"
      v-model="radioValue"
    >
    <label
      class="nhsuk-label nhsuk-radios__label base-radio__label"
      :class="{ 'base-radio__label-description': !!description}"
      :for="inputId"
      :data-testid="getTestId('label')"
    >
      <slot
        name="option"
      >
        <span
          v-if="label"
          class="base-radio__container nhsuk-u-font-size-22"
          v-html="capitalizeText($t(label))"
        />
        <span v-if="description">{{ description }}</span>
      </slot>
    </label>

    <slot/>

    <template
      v-if="!isMulti"
    >
      <input-details/>
    </template>
  </div>
</template>

<script>
  import { capitalize } from 'lodash'

  import InputDetails from '@/components/forms/input-details/InputDetails'

  import testid from '@/mixins/testid/testid'

  export default {
    components: {
      InputDetails,
    },

    mixins: [
      testid(),
    ],

    props: {
      ariaInvalid: {
        type: Boolean,
        default: false,
      },
      describedBy: {
        type: String,
        default: '',
      },
      inputValue: {
        type: [Boolean, Number, String],
        required: true,
      },
      isAnySelected: {
        type: Boolean,
        required: false,
        default: false,
      },
      isFirst: {
        type: Boolean,
        required: false,
        default: false,
      },
      isMulti: {
        type: Boolean,
        required: false,
        default: false,
      },
      isRequired: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        required: false,
        default: '',
      },
      name: {
        type: String,
        required: true,
      },
      description: {
        type: String,
        default: '',
      },
      value: {
        type: [Number, String, Boolean],
        required: false,
        default: false,
      },
    },

    methods: {
      onChange() {
        this.$emit('input', this.inputValue)
      },
      getTestId(slug) {
        return ['base-radio', slug]
          .filter(Boolean)
          .join('-')
      },
      capitalizeText(text) {
        return capitalize(text)
      },
    },

    computed: {
      inputId() {
        return `input-${this.name}-${this.uuid}`
      },
      isChecked() {
        return this.value == this.inputValue
      },
      radioValue: {
        get() {
          return this.value
        },
        set() {
          this.onChange()
        },
      },
      tabindex() {
        return this.isChecked || (!this.isAnySelected && this.isFirst) ? 0 : -1
      },
    },
  }
</script>

<style lang="scss">
  $block: 'base-radio';

  .#{$block} {
    font-size: 1.4rem;
    &--is-multi {
      margin: 0.8rem 0 0;
    }
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    &__label {
      display: flex;
      align-items: center;
      cursor: pointer;
      &:active {
        .base-radio__dot {
          border-color: color(dark-primary);
          &::before {
            background-color: color(dark-primary);
          }
        }
      }
    }
    &__input {
      max-width: 0;
      max-height: 0;
      position: absolute;
      overflow: hidden;
      opacity: 0;
      appearance: none;
      &:checked + .#{$block}__label > .#{$block}__dot {
        border-width: 2px;
        &::before {
          visibility: visible;
          transform: scale(1);
        }
      }
    }
    &__label-description {
      align-items: flex-start;
      flex-direction: column;
      span.base-radio__container {
        font-weight: 600;
      }
    }
    &__dot {
      display: inline-block;
      flex-shrink: 0;
      width: 2rem;
      height: 2rem;
      margin-right: 1rem;
      position: relative;
      z-index: z-index(base);
      border: 1px solid color(input-border);
      border-radius: 50%;
      transform: translateZ(0);
      &::before {
        content: '';
        width: 1rem;
        height: 1rem;
        position: absolute;
        top: .3rem;
        left: .3rem;
        background-color: color(input-border);
        border-radius: 50%;
        transition:
          transform .3s cubic-bezier(0, 0.8, 0.5, 1),
          background-color .3s cubic-bezier(0, 0.8, 0.5, 1);
        transform: scale(0);
        transform-origin: center;
        visibility: hidden;
      }
    }
    &__container {
      width: 100%;
    }
    .input-details--show-error {
      padding: 0.3rem 0 0 0;
    }
  }
  .is-tab {
    .#{$block}__input:focus + .#{$block}__label {
      @include outline;
    }
  }

  .nhsuk-radios__input + .nhsuk-radios__label {
    &::before {
      border: 2px solid color(input-border) !important;
    }
    &::after {
      border: 10px solid color(input-border) !important;
    }
  }
</style>
