<template>
  <div class="header-view">
    <breadcrumbs />
    <!-- <transition-fade>
      <button
        v-if="showGoBackButton"
        class="header-view__back"
        data-testid="header-view-back"
        type="button"
        @click="goBack">
          <inline-svg
            class="header-view__arrow"
            aria-labelledby="back-button-text"
            src="arrow_left.svg"
          />
          <span
            id="back-button-text"
            class="header-view__back-text"
          >
            {{ $t('Back') }}
          </span>
      </button>
    </transition-fade>

    <h1
      class="header-view__heading"
      data-testid="header-view-heading"
    >
      {{ $t(pageName) }}
    </h1> -->
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs'
  import goBack from '@/mixins/goBack'
  import pageName from '@/mixins/pageName'

  import eventBus from '@/tools/event-bus'

  // import TransitionFade from '@/transitions/transition-fade/TransitionFade'

  export default {
    components: {
      // TransitionFade,
      Breadcrumbs,
    },

    mixins: [
      goBack,
      pageName,
    ],

    methods: {
      ...mapActions('dialog', [
        'openDialog',
      ]),
      tryGoBack() {
        if (this.isPluginKioskMode && this.$route.meta.pluginHome) {
          this.askForPin()
        } else {
          this.goBack()
        }
      },
      askForPin() {
        this.openDialog({ component: 'KioskModePin', props: { eventBus } })
      },
      onPinValidation(isValid) {
        if (!isValid) return

        this.goBack()
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapGetters('kioskMode', [
        'isPluginKioskMode',
      ]),
      ...mapGetters('program', [
        'getSectionNameOfArticle',
      ]),
      hasGoBackButton() {
        const { $route: { meta } } = this

        return meta?.toParent || meta?.hasHeaderArrow !== false
      },
      showGoBackButton() {
        return this.hasGoBackButton && !this.isLoading
      },
      isLoading() {
        return this.getLoadingStatesForActions([
          'optionalProfileData/optionalProfileData',
          'optionalProfileData/updateOptionalData',
          'articles/changeProgress',
          'program/fetchNextSection',
        ])
      },
      text() {
        return this.sectionArticleName
          || this.$route.meta?.header
          || this.$route.matched
              .reduce((acc, { meta }) => (
                meta?.header || acc
              ), '')
          || this.$route.name
      },
      isArticle() {
        return this.$route.name === 'ArticleView'
      },
      sectionArticleName() {
        return this.getSectionNameOfArticle(this.$route?.params?.id)
      },
    },
  }
</script>

<style lang="scss">
  .header-view {
    display: none;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: $header-view_height;
    padding: 0 1rem;
    color: color(_white);
    background-color: color(primary);
    @include min-lg {
      display: flex;
      justify-content: flex-start;
      height: $header-view_height--lg;
      padding: 0 6rem;
    }
    &__back {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0;
      position: absolute;
      top: 0;
      left: 1rem;
      color: inherit;
      font-weight: 600;
      @include min-lg {
        left: 6rem;
      }
    }
    &__back-text {
      display: none;
      @include min-lg {
        display: block;
        margin: 0 0 0 1rem;
      }
    }
    &__arrow {
      display: flex;
      align-items: center;
      width: 2rem;
    }
    &__heading {
      margin: 0;
      font-size: 1.4rem;
      @include min-lg {
        font-size: 2.2rem;
      }
    }
  }
</style>
