<template>
  <simple-tile
    class="support-view__tile"
    direction="column"
    :data-testid="name + '-tile'"
    :name="name"
  >
    <slot name="header" />
    <h2 class="support-view__header">
      {{ i18n('header') }}
    </h2>

    <p class="support-view__paragraph">
      {{ i18n('content-1') }}
    </p>

    <p class="support-view__paragraph">
      {{ i18n('content-2') }}
    </p>

    <p class="support-view__paragraph">
      {{ i18n('content-3') }}
    </p>

    <base-form
      ref="form"
      :showSummaryErrors="true"
      :hasSubmitButton="false"
      @submit="onSubmit"
    >
      <form-fields-generator
        v-bind="{ dataModel, formFields }"
        @initialize="onFormInitialize"
      />

      <div class="support-view__bottom">
        <p>{{ i18n('required') }}</p>

        <div class="support-view__buttons">
          <base-button
            light
            class="support-view__cancel"
            :data-testid="name + '-button-cancel'"
            :name="name + '__cancel'"
            @click="goBack"
          >
            {{ $t('Cancel') }}
          </base-button>

          <base-button
            class="support-view__submit"
            :data-testid="name + '-button-submit'"
            :type="'submit'"
            :name="name + '__submit'"
            :isLoading="isSaving"
          >
            {{ $t('Submit') }}
          </base-button>
        </div>
      </div>
    </base-form>
  </simple-tile>
</template>

<script>
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

  import { BaseButton } from '@/components/base'
  import BaseForm from '@/components/base/BaseForm'
  import FormFieldsGenerator from '@/components/forms/form-fields-generator/FormFieldsGenerator'
  import SimpleTile from '@/components/tiles/simple-tile'

  import getDiagnosticData from '@/helpers/support/getDiagnosticData'

  import { useFormMethods } from '@/mixins/form-fields-generator/index'
  import goBack from '@/mixins/goBack'
  import i18n from '@/mixins/i18n'
  import testid from '@/mixins/testid/testid'

  export default {
    components: {
      BaseForm,
      BaseButton,
      FormFieldsGenerator,
      SimpleTile,
    },

    mixins: [
      goBack,
      i18n,
      testid(),
      useFormMethods(),
    ],

    props: {
      name: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        dataModel: {},
      }
    },

    methods: {
      ...mapActions('support', [
        'sendMail',
      ]),
      ...mapMutations('snackbars', [
        'addSnackbar',
      ]),
      onSubmit(isValid) {
        if (!isValid) return

        if (this.dataModel.includeDiagnostic) {
          this.dataModel.diagnostic_data = getDiagnosticData()
        }

        this.sendMail(this.dataModel)
          .then(() => {
            this.addSnackbar({ message: this.i18n('message-sent') })
            this.reset()
          })
          .catch(() => this.addSnackbar({ message: this.$t('Something went wrong. Please try again.') }))
      },
      reset() {
        this.formMethods.resetModel(true)
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapState('layout', [
        'wW',
      ]),
      isSaving() {
        return this.getLoadingStatesForActions([
          'support/sendMail',
        ])
      },
      showBackgroundImage() {
        return this.wW >= 1024
      },
      formFields() {
        return [
          {
            type: 'SelectGroup',
            name: 'reason',
            default: undefined,
            props: {
              label: this.i18n('label-1'),
              options: [
                this.i18n('question'),
                this.i18n('error-message'),
                this.i18n('feedback'),
              ],
              capitalize: true,
              placeholder: this.i18n('placeholder-1'),
              validation: { required: true },
            },
          }, {
            type: 'TextareaGroup',
            name: 'content',
            props: {
              label: this.i18n('label-2'),
              info: this.i18n('info-2'),
              placeholder: this.i18n('placeholder-2'),
              validation: { min: 10, required: true },
            },
          }, {
            type: 'CheckboxGroup',
            name: 'includeDiagnostic',
            props: {
              label: this.i18n('label-3'),
              tooltip: this.i18n('tooltip'),
            },
          },
        ]
      },
    },

    slug: 'views.support',
  }
</script>

<style lang="scss">
  .support-view {
    @include min-lg {
      background: url('~@/assets/img/support-zdzisio.png') no-repeat top left;
      background-size: cover;
    }
    @media all and (min-width: 2100px) {
      background-size: contain;
    }
    &__wrapper {
      width: 100%;
      padding: 2rem 0;
      @include min-lg {
        padding: 4rem 0;
      }
    }
    &__tile {
      z-index: z-index(base);
      max-width: 53.5rem;
      margin: 0 auto;
      @include min-lg {
        margin: 0 4rem 0 auto;
      }
      @media all and (min-width: 1280px) {
        margin: 0 10.5rem 0 auto;
      }
      @include min-xl {
        margin: 0 18rem 0 auto;
      }
    }
    .simple-tile__main {
      padding: 2.2rem;
      @include min-lg {
        padding: 3.4rem;
      }
    }
    &__header {
      margin-top: 0;
    }
    &__paragraph {
      margin-bottom: 2.2rem;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    &__bottom {
      display: flex;
      flex-direction: column;
      margin-top: 2.2rem;
      @include min-sm {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    }
    &__buttons {
      display: flex;
      flex-direction: column;
      @include min-sm {
        display: block;
      }
    }
    &__cancel,
    &__submit {
      margin: 1.4rem auto 0;
    }
    &__cancel {
      @include min-sm {
        margin: 0 0.8rem 0 0;
      }
    }
    &__submit {
      @include min-sm {
        margin: 0 0 0 0.8rem;
      }
    }
  }
</style>
