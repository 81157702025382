var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isDesktop)?_c('header',{ref:"header",staticClass:"header",class:{
    'header--has-search-only': _vm.hasSearchOnly,
  },attrs:{"data-testid":"header"}},[_c('div',{staticClass:"header__container header__container--desktop"},[_c('div',{staticClass:"header__items"},[_c('div',{staticClass:"header__item header__programs"},[_c('base-header-navigation-drawer-button'),_c('div',{staticClass:"header__program"},[_vm._v(" "+_vm._s(_vm.getCurrentProgramName)+" ")])],1),_c('base-header-tabs',{staticClass:"header__item header__item--center",attrs:{"items":_vm.navItems}}),_c('div',{staticClass:"header__item header__item--end header__actions"},[_c('search-box',{attrs:{"data-testid":"header-search","ariaLabel":_vm.$t('Site')},model:{value:(_vm.form.search),callback:function ($$v) {_vm.$set(_vm.form, "search", $$v)},expression:"form.search"}}),_c('div',{staticClass:"header__actions-profile",class:{'header__actions-profile-secondary-logo': _vm.currentProgramSecondaryLogo}},[_c('router-link',{staticClass:"header__user",attrs:{"aria-label":"My Profile","data-testid":"header-profile","to":{ name: 'Profile' }}},[_c('img',{staticClass:"header__avatar",attrs:{"alt":"","src":_vm.getCurrentUserAvatar}})]),_c('div',{staticClass:"header__menu-container"},[_c('h3',[_vm._v(_vm._s(_vm.currentUserFullName))]),_c('div',[_c('base-anchor',{staticClass:"header__menu-container-item",attrs:{"data-testid":"menu-profile","to":{ name: 'Profile' }}},[_vm._v(" "+_vm._s(_vm.$t('My profile'))+" ")]),_c('button',{staticClass:"header__menu-container-item",attrs:{"data-testid":"menu-logout"},on:{"click":_vm.handleLogout}},[_vm._v(" "+_vm._s(_vm.$t('Logout'))+" ")])],1)]),(_vm.currentProgramSecondaryLogo)?_c('img',{staticClass:"header__secondary-logo",attrs:{"alt":"","src":_vm.currentProgramSecondaryLogo}}):_vm._e()],1)],1)],1),_c('base-header-view')],1)]):(!_vm.showBurgerMenu)?_c('header',{ref:"header",staticClass:"header",class:{
    'header--has-search-only': _vm.hasSearchOnly,
  },attrs:{"data-testid":"header"}},[_c('div',{staticClass:"header__container header__container--mobile"},[_c('div',{staticClass:"header__items"},[_c('base-header-navigation-drawer-button'),_c('base-header-tabs',{attrs:{"items":_vm.navItems}}),_c('button',{staticClass:"search-box__btn search-box__submit nhsuk-search__submit",class:{
          'is-active': _vm.showInput,
        },attrs:{"aria-label":"Search","data-testid":"search-box-submit","type":"button"},on:{"click":function($event){_vm.showInput = !_vm.showInput}}},[_c('inline-svg',{staticClass:"search-box__icon",attrs:{"role":"presentation","src":"icons/icon-search.svg"}})],1),_c('div',{staticClass:"header__menu-container"},[_c('base-header-menu-button',_vm._b({attrs:{"id":("mobile_" + _vm.uuid)}},'base-header-menu-button',{ isDesktop: _vm.isDesktop },false)),_c('base-menu',{attrs:{"id":("mobile_" + _vm.uuid)}})],1)],1),(_vm.showInput)?_c('div',{ref:"search",staticClass:"header__search"},[_c('search-box',{attrs:{"data-testid":"header-search"},model:{value:(_vm.form.search),callback:function ($$v) {_vm.$set(_vm.form, "search", $$v)},expression:"form.search"}})],1):_vm._e()])]):_c('header',{ref:"header",staticClass:"header nhsuk-header nhsuk-header--white",attrs:{"data-testid":"header"}},[_c('div',{staticClass:"nhsuk-header__container header__container header__container--mobile"},[_c('div',{staticClass:"nhsuk-header__content",attrs:{"id":"content-header"}},[_c('base-header-navigation-drawer-button'),_c('div',{staticClass:"nhsuk-header__content-right"},[_c('div',{staticClass:"nhsuk-header__menu"},[_c('button',{staticClass:"nhsuk-header__menu-toggle",attrs:{"data-testid":"toggle-menu","aria-controls":"header-navigation","aria-expanded":"false"},on:{"click":function($event){_vm.showMenuOptions=!_vm.showMenuOptions}}},[_vm._v(" Menu ")])]),_c('button',{staticClass:"search-box__btn search-box__submit nhsuk-search__submit",class:{
            'is-active': _vm.showInput,
          },attrs:{"aria-label":"Search","data-testid":"search-box-submit","type":"button"},on:{"click":function($event){_vm.showInput = !_vm.showInput}}},[_c('inline-svg',{staticClass:"search-box__icon",attrs:{"role":"presentation","src":"icons/icon-search.svg"}})],1)])],1),(_vm.showInput)?_c('div',{ref:"search",staticClass:"header__search"},[_c('search-box',{attrs:{"data-testid":"header-search"},model:{value:(_vm.form.search),callback:function ($$v) {_vm.$set(_vm.form, "search", $$v)},expression:"form.search"}})],1):_vm._e()]),(_vm.showMenuOptions)?_c('nav',{staticClass:"nhsuk-header__navigation",class:{
      'js-show': _vm.showMenuOptions
    },attrs:{"data-testid":"header-navigation","id":"header-navigation","role":"navigation","aria-label":"Primary navigation","aria-labelledby":"label-navigation"}},[_c('div',{staticClass:"nhsuk-width-container"},[_c('div',{staticClass:"nhsuk-width-container"},[_c('p',{staticClass:"nhsuk-header__navigation-title"},[_c('span',{attrs:{"id":"label-navigation"}},[_vm._v("Menu")]),_c('button',{staticClass:"nhsuk-header__navigation-close",attrs:{"data-testid":"close-menu"},on:{"click":function($event){_vm.showMenuOptions=false}}},[_c('inline-svg',{staticClass:"nhs-icon-close",attrs:{"role":"presentation","src":"icons/nhs-icon-close.svg"}}),_c('span',{staticClass:"nhsuk-u-visually-hidden"},[_vm._v("Close menu")])],1)]),_c('ul',{staticClass:"nhsuk-header__navigation-list"},[_vm._l((_vm.menuItems),function(menuItem){return _c('li',{key:menuItem.name,staticClass:"nhsuk-header__navigation-item nhsuk-header__navigation-item--for-mobile"},[_c('button',{style:({ width: '100%', padding: 0 }),attrs:{"dataTestid":menuItem.name},on:{"click":function($event){_vm.showMenuOptions=false}}},[_c('router-link',{staticClass:"nhsuk-header__navigation-link",attrs:{"to":_vm.routerLinkTo(menuItem)}},[_vm._v(" "+_vm._s(menuItem.name)+" "),_c('inline-svg',{staticClass:"nhs-icon-close",attrs:{"role":"presentation","src":"icons/nhs-icon-right-arrow.svg"}})],1)],1)])}),_c('li',[_c('button',{staticClass:"nhsuk-header__navigation-link",attrs:{"data-testid":"logout"},on:{"click":_vm.handleLogout}},[_vm._v(" "+_vm._s(_vm.$t('Logout'))+" "),_c('inline-svg',{staticClass:"nhs-icon-close",attrs:{"role":"presentation","src":"icons/nhs-icon-right-arrow.svg"}})],1)])],2)])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }