<template>
  <div
    class="logged-out"
    data-testid="auth-logged-out"
  >
    <auth-wrapper :hasFor="false">
      <template #title>
        {{ $t(`${$options.slug}.title`) }}
      </template>

      <template #subtitle>
        {{ $t(`${$options.slug}.subtitle`) }}
      </template>

      <base-button
        class="logged-out__login-btn"
        data-testid="auth-logged-out-button"
        :link="{ name: 'AuthLogin' }"
      >
        {{ $t(`${$options.slug}.button`) }}
      </base-button>
    </auth-wrapper>
  </div>
</template>

<script>
  import { BaseButton } from '@/components/base'
  import AuthWrapper from '@/components/wrappers/AuthWrapper'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.auth.auth-logged-out.meta.title'),
      }
    },

    components: {
      AuthWrapper,
      BaseButton,
    },

    slug: 'views.auth.auth-logged-out',
  }
</script>

<style lang="scss">
  .logged-out {
    &__login-btn {
      margin: 3.2rem auto;
      font-weight: 800;
    }
  }
</style>
