<template>
  <base-main
    data-testid="weight-tracking-log"
    :loading="$options.loading"
  >
    <container
      first
    >
     <stepper-custom-form
        ref="form"
        data-testid="weight-tracking-log-form"
        :buttonLabels="{
          finishLabel: showWarning ? 'Yes, continue' : 'Log weight',
          previousLabel: showWarning ? 'No, cancel' : 'Previous'
        }"
        :isLoading="isSaving"
        @cancel="goBack"
        @submit="onSubmit"
        @previous="previous"
      >
        <template #default>
          <stepper-custom-step
            v-for="(formField, i) in [dateField, valueField]"
            :key="i"
            data-testid="weight-tracking-log-stepper-custom-step"
            :title="titleFormField(formField) || i18n('header')"
            :description="i === 0 && isEdit ? 'You are editing a log for the date below' : ''"
          >
            <div v-if="showWarning">
              <h2>
                This will replace all weight data previously recorded for this date.
              </h2>
              <h2>Do you wish to continue?</h2>
            </div>
            <div v-if="!showWarning">
              <span
                v-if="formField"
                class="log-weight-tracking__unit"
                data-testid="weight-tracking-log-unit"
              >
                <form-fields-generator-field
                  :dataModel="form"
                  v-bind="{ field: formField }"
                />
              </span>
              <span v-else-if="isStonesAndPounds">
                <label
                  class="input-group__label"
                  for="weight_goal">{{ i18n('input-label') }} (required)</label>
                <div class="log-weight-stones-pounds__container">
                  <div class="log-weight-tracking__container">
                    <input-group
                      :name="'stones'"
                      :placeholder="i18n('input-placeholder')"
                      :step="0.01"
                      :testid="'weight-tracking-log'"
                      :type="'number'"
                      :validation="$options.validation.stones"
                      v-model="form.stones"
                    />
                    <span
                      class="log-weight-tracking__unit"
                      data-testid="weight-tracking-log-unit"
                    >
                      {{ unit[0] }}
                    </span>
                  </div>
                  <div class="log-weight-tracking__container">
                    <input-group
                      :max="14"
                      :name="'pounds'"
                      :placeholder="i18n('input-placeholder')"
                      :step="0.01"
                      :testid="'weight-tracking-log'"
                      :type="'number'"
                      :validation="$options.validation.stones_pounds"
                      v-model="form.pounds"
                    />
                    <span
                      class="log-weight-tracking__unit"
                      data-testid="weight-tracking-log-unit"
                    >
                      {{ unit[1] }}
                    </span>
                  </div>
                </div>
              </span>
              <span v-else-if="isPounds">
                <label
                  class="input-group__label"
                  for="weight_goal">{{ i18n('input-label') }} (required)</label>
                <div class="log-weight-stones-pounds__container">
                  <div class="log-weight-tracking__container">
                    <input-group
                      :name="'pounds'"
                      :placeholder="i18n('input-placeholder')"
                      :step="0.01"
                      :testid="'weight-tracking-log'"
                      :type="'number'"
                      :validation="$options.validation.pounds"
                      v-model="form.pounds"
                    />
                    <span
                      class="log-weight-tracking__unit"
                      data-testid="weight-tracking-log-unit"
                    >
                      {{ unit }}
                    </span>
                  </div>
                </div>
              </span>
            </div>
          </stepper-custom-step>
        </template>
     </stepper-custom-form>
    </container>
  </base-main>
</template>

<script>
  import moment from 'moment'
  import { mapActions, mapGetters, mapMutations } from 'vuex'

  import {
    BaseMain,
  } from '@/components/base'
  import Container from '@/components/container/Container'
  import FormFieldsGeneratorField from '@/components/forms/form-fields-generator/FormFieldsGeneratorField'
  import InputGroup from '@/components/forms/input-group/InputGroup'
  import StepperCustomForm from '@/components/stepper-custom/StepperCustomForm'
  import StepperCustomStep from '@/components/stepper-custom/StepperCustomStep'
  import { DATE_FORMATS, SPECIAL_WEIGHT_VALUE, UNIT_CONVERTERS } from '@/constants'
  import goBack from '@/mixins/goBack'

  import i18n from '@/mixins/i18n'

  import weightUnitConverter from '@/mixins/weight-unit-converter/weightUnitConverter'
  import eventBus from '@/tools/event-bus'

  export default {
    components: {
      FormFieldsGeneratorField,
      BaseMain,
      Container,
      StepperCustomForm,
      StepperCustomStep,
      InputGroup,
    },

    mixins: [
      i18n,
      goBack,
      weightUnitConverter,
    ],

    props: {
      type: {
        type: String,
        required: false,
        default: '',
      },
      id: {
        type: String,
        required: false,
        default: '',
      },
    },

    mounted() {
      if (!this.id) return
      this.isEdit = true

      this.fetchByDay(this.id).then(item => {
        this.itemById = item
        this.fillForm()
      })
    },

    created() {
      this.$options.validation.weight_goal.min_value = this.inputMinMax.min
      this.$options.validation.weight_goal.max_value = this.inputMinMax.max
      this.form.date = moment.utc().utcOffset(this.getTimezoneOffset).format(DATE_FORMATS.dateShort)
    },

    data() {
      return {
        form: {
          weight_goal: '',
          stones: '',
          pounds: '',
          date: '',
        },
        showWarning: false,
        isEdit: false,
      }
    },

    methods: {
      ...mapActions('weightTracking', [
        'logWeight',
        'fetchByDay',
      ]),
      ...mapMutations('snackbars', [
        'addSnackbar',
      ]),
      async onSubmit(isValid) {
        if (!this.showWarning && this.form.date !== this.id) {
          const { label, value } = await this.fetchByDay(this.form.date)
          if (label && value) {
            this.showWarning = true
            return
          }
        }
        if (!isValid || this.isSaving) return

        const formData = new FormData()

        let { weight_goal: weightGoal } = this.form

        if (!this.isSpecialNumber) {
          if (this.isStonesAndPounds) {
            const pounds = parseInt(this.form.stones * UNIT_CONVERTERS.poundsInStones, 10)
              + parseInt(this.form.pounds, 10)
            weightGoal = (Math.round((pounds / UNIT_CONVERTERS.kgInPounds) * 100)) / 100
          } else if (this.isPounds) {
            weightGoal = (Math.round(
              (this.form.pounds / UNIT_CONVERTERS.kgInPounds) * 100,
            )
            ) / 100
          }
        }

        const form = {
          date: this.form.date,
          weight_goal: weightGoal,
        }

        Object.entries(form).forEach(([key, value]) => {
          formData.append(key, value)
        })
        this.logWeight({ form: formData, type: this.type })
          .then(() => {
            this.addSnackbar({ message: this.$t('Weight log saved') })
            eventBus.$emit('weightTracking/refresh')
            this.goBack()
          })
      },
      titleFormField(formField) {
        return formField?.props?.legend || formField?.props?.label || ''
      },
      previous() {
        if (this.showWarning) {
          this.goBack()
        }
      },
      fillForm() {
        const [value] = this.itemById.value
        const { stones, pounds, value: onlyPounds } = this.convertToStonesOrPoundsIfNeeded(value)
        this.form.weight_goal = value
        this.form.stones = stones
        this.form.pounds = pounds || onlyPounds
        this.form.date = this.id
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapGetters('user', [
        'getCurrentUserWeightUnit',
        'isMetric',
        'isPounds',
        'isStonesAndPounds',
        'getTimezoneOffset',
      ]),
      dateField() {
        /* eslint-disable camelcase */
        return {
          type: 'CalendarPickerGroup',
          class: 'log-glucose__form-field',
          name: 'date',
          props: {
            format: DATE_FORMATS.dateShort,
            formatted: DATE_FORMATS.date,
            hasHiddenAsterisk: true,
            label: 'Select the date of your tracking',
            maxDate: moment
              .utc()
              .utcOffset(this.getTimezoneOffset)
              .format('YYYY-MM-DD 23:59'),
            minDate: moment
              .utc(this.settings?.created_at, 'YYYY-MM-DD HH:mm:ss')
              .utcOffset(this.getTimezoneOffset)
              .format('YYYY-MM-DD 00:00'),
            testid: this.dataTestid,
            outputFormat: DATE_FORMATS.dateShort,
            validation: { required: true },
            required: true,
            disabled: this.isEdit,
          },
        }
        /* eslint-enable camelcase */
      },
      valueField() {
        if (this.isStonesAndPounds || this.isPounds) {
          return null
        }
        return {
          type: 'InputGroup',
          name: 'weight_goal',
          class: 'weight-tracking-log',
          props: {
            afterText: this.unit,
            hasHiddenAsterisk: true,
            label: this.i18n('input-label'),
            legend: this.i18n('header'),
            placeholder: this.i18n('input-placeholder'),
            step: 0.01,
            type: 'number',
            validation: this.$options.validation.weight_goal,
          },
        }
      },
      unit() {
        return this.getCurrentUserWeightUnit.abbr
      },
      isSaving() {
        return this.getLoadingStatesForActions([
          'weightTracking/logWeight',
        ])
      },
      inputMinMax() {
        return this.unit == 'lbs'
          ? { min: 40, max: SPECIAL_WEIGHT_VALUE }
          : { min: 20, max: SPECIAL_WEIGHT_VALUE }
      },
      isSpecialNumber() {
        return this.form.weight_goal === `${SPECIAL_WEIGHT_VALUE}`
      },
    },

    // TODO important to static translate text /api/init
    slug: 'component.dialog.log-weight-dialog',

    validation: {
      weight_goal: {
        required: true,
        decimal: 2,
        min_value: 20,
        max_value: SPECIAL_WEIGHT_VALUE,
      },
      stones: {
        required: true,
      },
      pounds: {
        required: true,
      },
      stones_pounds: {
        required: true,
        max_value: 14,
      },
    },
  }
</script>

<style lang="scss">
  .log-weight-stones-pounds__container {
    display: flex;
    .log-weight-tracking {
      &__container {
        width: 100%;
        .input-group {
          margin: 0;
        }
      }
      &__unit {
        margin: 0;
        margin-left: 1rem;
        margin-right: 3.4rem;
      }

    }
  }
  .step-warning {
    display: flex;
    justify-content: center;
  }
  .log-weight-tracking {
    &__container {
      display: flex;
      align-items: center;
      > div {
        width: 100%;
      }
      > span {
        margin-left: 1rem;
      }
    }
    &__content {
      width: 100%;
    }
    &__input-layout {
      display: grid;
      grid-column-gap: 1rem;
      grid-template-columns: 1fr auto;
      padding: 0 1rem 0 0;
    }
    &__unit {
      margin: 3.4rem 0 0;
    }
    &.modular-tile {
      &__main {
        padding: 0 3rem 3rem;
      }
    }
    .form__end-row {
      margin: 3rem 0 0;
    }
    .input-group {
      &__label {
        &::after {
          content: '';
        }
      }
      &:first-child {
        margin: 0;
      }
    }
  }
</style>
