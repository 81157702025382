<template>
  <base-main
    class="coach"
    :loading="$options.loading"
    :data-testid="'coach-plugin-coach'"
  >
    <container
      v-if="coach"
      first
    >
      <simple-tile>
        <div class="coach__layout">
          <div class="coach__photo">
            <img
              alt=""
              class="coach__img"
              :src="coachImage"
            >
          </div>
          <div class="coach__info">
            <h2
              class="coach__name"
              data-testid="coach-plugin-coach-name"
            >
              {{ coach.name }}
            </h2>
            <p
              class="coach__title"
              data-testid="coach-plugin-coach-title"
            >
              {{ coach.title }}
            </p>
            <p
              v-if="coach.description"
              class="coach__description"
              data-testid="coach-plugin-coach-description"
            >
              {{ coachDescription }}
              <button
                v-if="showExpandDescription"
                class="coach__read-more"
                type="button"
                data-testid="coach-plugin-coach-read-more-button"
                @click="expandDescription"
              >
                {{ $t(`${$options.slug}.read-more`) }}
              </button>
            </p>
            <base-button
              no-margin
              class="coach__link"
              data-testid="coach-plugin-coach-book-appointment-button"
              :link="{ name: 'CoachingPluginNewAppointment' }"
            >
              {{ $t('Book appointment') }}
            </base-button>
          </div>
        </div>
      </simple-tile>
    </container>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'

  import BaseButton from '@/components/base/BaseButton'
  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'
  import SimpleTile from '@/components/tiles/simple-tile'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.coaching-plugin.coaching-plugin-coach.meta.title'),
      }
    },

    components: {
      BaseButton,
      BaseMain,
      Container,
      SimpleTile,
    },

    created() {
      if (this.preferences) return

      this.fetchPreferences(this.getCurrentUserId)
        .catch(() => (
          this.$router.replace({ name: 'CoachingPluginStart', params: { fromRedirect: true } })
        ))
    },

    watch: {
      preferences: {
        handler() {
          if (!this.preferences || this.coach) return

          this.fetchCoach({ programId: this.currentProgramId, userId: this.getCurrentUserId })
            .catch(() => this.$router.replace({ name: 'CoachingPlugin' }))
        },
        immediate: true,
      },
    },

    data() {
      return {
        descriptionExpanded: false,
      }
    },

    methods: {
      ...mapActions('coaching', [
        'fetchCoach',
        'fetchPreferences',
      ]),
      expandDescription() {
        this.descriptionExpanded = true
      },
    },

    computed: {
      ...mapGetters('coaching', [
        'coachImage',
      ]),
      ...mapGetters('program', [
        'currentProgramId',
      ]),
      ...mapGetters('user', [
        'getCurrentUserId',
      ]),
      ...mapState('coaching', [
        'coach',
        'preferences',
      ]),
      descriptionOverLimit() {
        return this.coach.description.length > this.$static.descriptionLimit
      },
      showExpandDescription() {
        return !this.descriptionExpanded && this.descriptionOverLimit
      },
      coachDescription() {
        return this.descriptionExpanded || !this.descriptionOverLimit
          ? this.coach.description
          : `${this.coach.description.substr(0, this.$static.descriptionLimit)}...`
      },
    },

    slug: 'views.plugins.coaching-plugin.coaching-plugin-coach',

    loading: [
      'coaching/fetchCoach',
      'coaching/fetchPreferences',
    ],

    static() {
      return {
        descriptionLimit: 120,
      }
    },
  }
</script>

<style lang="scss">
  .coach {
    &__layout {
      display: flex;
      flex-direction: column;
      @include min-md {
        flex-direction: row;
      }
    }
    &__photo {
      width: 18rem;
      min-width: 18rem;
      height: 18rem;
      @include min-md {
        margin: 0 4rem 0 0;
      }
    }
    &__name {
      margin: 0;
      font-size: 2.2rem;
      font-weight: 800;
    }
    &__title {
      margin: 0.4rem 0 0;
      color: color(primary);
      font-size: 1.8rem;
      font-weight: 800;
    }
    &__description {
      margin-top: 2rem;
      font-size: 1.4rem;
    }
    &__link {
      margin-top: 2rem;
    }
    &__read-more {
      color: color(dark-grey);
      font-size: 1.2rem;
      text-decoration: underline;
    }
  }
</style>
