<template>
  <div
    data-testid="myDatepicker"
    class="datepicker"
    ref="datepicker">
    <div class="date">
      <label
        for="id-textbox-1"
        class="input-group__label">
        {{ labelText }}
      </label>
      <div class="group">
        <input
          type="text"
          class="input-group__input"
          placeholder="dd/mm/yyyy"
          data-testid="id-textbox-1"
          aria-describedby="id-description-1"
          :required="validation.required"
          :aria-required="validation.required"
          :disabled="disabled">
        <!-- <span
          class="desc"
          id="id-description-1">
          (
          <span class="sr-only">
            date format:
          </span>
          mm/dd/yyyy)
        </span> -->
        <button
          data-testid="button-choose-date"
          type="button"
          class="icon"
          aria-label="Choose Date"
          :disabled="disabled">
          <inline-svg
            class="tracking-datepicker__calendar"
            role="presentation"
            src="icons/icon-calendar.svg"
          />
        </button>
      </div>
    </div>
    <div
      ref="datepickerDialog"
      data-testid="id-datepicker-1"
      class="datepicker-dialog"
      role="dialog"
      aria-modal="true"
      aria-labelledby="id-dialog-label">
      <div
        class="datepicker-header">
        <button
          type="button"
          data-testid="fa-angle-double-left"
          class="prev-year"
          aria-label="previous year">
          <!-- eslint-disable-next-line vue/no-parsing-error -->
          &lt;&lt;
        </button>
        <button
          type="button"
          data-testid="fa-angle-left"
          class="prev-month"
          aria-label="previous month">
          <!-- eslint-disable-next-line vue/no-parsing-error -->
          &lt;
        </button>
        <h2
          data-testid="id-dialog-label"
          class="month-year"
          aria-live="polite">
          Month Year
        </h2>
        <button
          type="button"
          data-testid="fa-angle-right"
          class="next-month"
          aria-label="next month">
          >
        </button>
        <button
          type="button"
          data-testid="fa-angle-double-right"
          class="next-year"
          aria-label="next year">
          >>
        </button>
      </div>
      <!-- eslint-disable-next-line vuejs-accessibility/no-redundant-roles -->
      <table
        class="dates"
        data-testid="myDatepickerGrid"
        role="grid"
        aria-labelledby="id-dialog-label">
        <thead>
          <tr>
            <th
              scope="col"
              abbr="Sunday">Su
            </th>
            <th
              scope="col"
              abbr="Monday">Mo
            </th>
            <th
              scope="col"
              abbr="Tuesday">Tu
            </th>
            <th
              scope="col"
              abbr="Wednesday">We
            </th>
            <th
              scope="col"
              abbr="Thursday">Th
            </th>
            <th
              scope="col"
              abbr="Friday">Fr
            </th>
            <th
              scope="col"
              abbr="Saturday">Sa
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td
              v-for="index in 6"
              :key="`${index}r`"
              class="disabled"
              tabindex="-1"/>
            <td
              tabindex="-1"
              data-date="2020-02-01">
              1
              </td>
          </tr>
          <tr
            v-for="index in 4"
            :key="`${index}d`">
            <td tabindex="-1"/>
            <td tabindex="-1"/>
            <td tabindex="-1"/>
            <td tabindex="-1"/>
            <td tabindex="-1"/>
            <td tabindex="-1"/>
            <td tabindex="-1"/>
          </tr>
          <tr>
            <td
              v-for="index in 2"
              :key="`${index}y`"
              tabindex="-1"/>
            <td
              v-for="index in 5"
              :key="`${index}x`"
              class="disabled"
              tabindex="-1"/>
          </tr>
        </tbody>
      </table>
      <div
        class="dialog-message"
        aria-live="polite"/>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import { mapMutations } from 'vuex'

  import {
    DATE_FORMATS,
  } from '@/constants'

  // import getCssColorVariable from '@/helpers/getCssColorVariable'

  import addVModel from '@/mixins/addVModel'
  import i18n from '@/mixins/i18n'
  import labelText from '@/mixins/labelText'
  import testid from '@/mixins/testid/testid'

  export default {
    components: {
    },

    mixins: [
      addVModel('model', String, ''),
      i18n,
      testid(),
      labelText,
    ],

    props: {
      autoClose: {
        type: Boolean,
        required: false,
        default: false,
      },
      autoResize: {
        type: Boolean,
        required: false,
        default: false,
      },
      ariaDescribedby: {
        type: String,
        required: false,
        default: '',
      },
      buttonNowTranslation: {
        type: String,
        required: false,
        default: 'Now',
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      disabledDates: {
        type: Array,
        required: false,
        default: () => [],
      },
      availableDates: {
        type: Array,
        required: false,
        default: () => [],
      },
      disabledHours: {
        type: Array,
        required: false,
        default: () => [],
      },
      disabledWeekly: {
        type: Array,
        required: false,
        default: () => [],
      },
      firstDayOfWeek: {
        type: Number,
        required: false,
        default: 1,
      },
      format: {
        type: String,
        required: false,
        default: DATE_FORMATS.dateTime,
      },
      formatted: {
        type: String,
        required: false,
        default: DATE_FORMATS.date,
      },
      hasForm: {
        type: Boolean,
        required: false,
        default: false,
      },
      hasHiddenAsterisk: {
        type: Boolean,
        default: true,
      },
      icon: {
        type: String,
        required: false,
        default: null,
      },
      inline: {
        type: Boolean,
        required: false,
        default: false,
      },
      label: {
        type: String,
        required: false,
        default: 'Enter or select a date',
      },
      maxDate: {
        type: String,
        required: false,
        default: null,
      },
      minDate: {
        type: String,
        required: false,
        default: null,
      },
      minuteInterval: {
        type: Number,
        required: false,
        default: 1,
      },
      name: {
        type: String,
        required: true,
      },
      noButton: {
        type: Boolean,
        required: false,
        default: false,
      },
      noButtonNow: {
        type: Boolean,
        required: false,
        default: true,
      },
      noHeader: {
        type: Boolean,
        required: false,
        default: false,
      },
      noWeekendsDays: {
        type: Boolean,
        required: false,
        default: false,
      },
      outputFormat: {
        type: String,
        required: false,
        default: DATE_FORMATS.dateTime,
      },
      overlay: {
        type: Boolean,
        required: false,
        default: false,
      },
      period: {
        type: String,
        required: false,
        default: 'day',
      },
      tooltip: {
        type: String,
        required: false,
        default: null,
      },
      validation: {
        type: Object,
        required: false,
        default: () => ({}),
      },
    },

    mounted() {
      this.datepicker(this.$refs.datepicker)
    },

    destroyed() {
      document.body.removeEventListener(
        'mouseup',
        this.handleBackgroundMouseUpBind,
        true,
      )
    },

    watch: {
      model() {
        const d = moment(this.model, 'YYYY-MM-DD')
        this.textboxNode.value = `${d.date()}/${d.month() + 1}/${d.year()}`
      },
      availableDates: {
        handler(value, oldValue) {
          if (value[0] !== oldValue[0]) {
            this.selectedDay = new Date(this.focusDay)
            this.updateGrid()
          }
        },
      },
    },

    data() {
      return {
        handleBackgroundMouseUpBind: this.handleBackgroundMouseUp.bind(this),
        handleDayClickBind: this.handleDayClick.bind(this),
        handleDayKeyDownEnterBind: this.handleDayKeyDownEnter.bind(this),
        modelFormatted: null,
        isVisible: false,
        buttonLabelChoose: 'Choose Date',
        buttonLabelChange: 'Change Date',
        dayLabels: [
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
        ],
        monthLabels: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ],
        messageCursorKeys: 'Cursor keys can navigate dates',
        lastMessage: '',
        textboxNode: '',
        buttonNode: '',
        dialogNode: '',
        messageNode: '',
        monthYearNode: '',
        prevYearNode: '',
        prevMonthNode: '',
        nextMonthNode: '',
        nextYearNode: '',
        okButtonNode: '',
        cancelButtonNode: '',
        tbodyNode: '',
        lastRowNode: null,
        days: [],
        focusDay: new Date(),
        selectedDay: new Date(0, 0, 1),
        isMouseDownOnBackground: false,
        oldAvailableDates: new Set(),
        daysOfWeek: 7,
        lastMonth: 11,
        firstMonth: 0,
      }
    },

    methods: {
      ...mapMutations('layout', [
        'set',
      ]),

      datepicker(cdp) {
        this.textboxNode = cdp.querySelector('input[type="text"')
        const d = new Date(this.model)
        this.textboxNode.value = `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
        this.buttonNode = cdp.querySelector('.group button')
        this.dialogNode = cdp.querySelector('[role="dialog"]')
        this.messageNode = this.dialogNode.querySelector('.dialog-message')

        this.monthYearNode = this.dialogNode.querySelector('.month-year')

        this.prevYearNode = this.dialogNode.querySelector('.prev-year')
        this.prevMonthNode = this.dialogNode.querySelector('.prev-month')
        this.nextMonthNode = this.dialogNode.querySelector('.next-month')
        this.nextYearNode = this.dialogNode.querySelector('.next-year')

        this.okButtonNode = this.dialogNode.querySelector('button[value="ok"]')
        this.cancelButtonNode = this.dialogNode.querySelector(
          'button[value="cancel"]',
        )

        this.tbodyNode = this.dialogNode.querySelector('table.dates tbody')

        this.initDatepicker()
      },
      initDatepicker() {
        this.textboxNode.addEventListener(
          'blur',
          this.setDateForButtonLabel.bind(this),
        )

        this.buttonNode.addEventListener(
          'keydown',
          this.handleButtonKeydown.bind(this),
        )
        this.buttonNode.addEventListener('click', this.handleButtonClick.bind(this))

        this.prevMonthNode.addEventListener(
          'click',
          this.handlePreviousMonthButton.bind(this),
        )
        this.nextMonthNode.addEventListener(
          'click',
          this.handleNextMonthButton.bind(this),
        )
        this.prevYearNode.addEventListener(
          'click',
          this.handlePreviousYearButton.bind(this),
        )
        this.nextYearNode.addEventListener(
          'click',
          this.handleNextYearButton.bind(this),
        )

        this.prevMonthNode.addEventListener(
          'keydown',
          this.handlePreviousMonthButton.bind(this),
        )
        this.nextMonthNode.addEventListener(
          'keydown',
          this.handleNextMonthButton.bind(this),
        )
        this.prevYearNode.addEventListener(
          'keydown',
          this.handlePreviousYearButton.bind(this),
        )
        this.nextYearNode.addEventListener(
          'keydown',
          this.handleNextYearButton.bind(this),
        )

        document.body.addEventListener(
          'mouseup',
          this.handleBackgroundMouseUpBind,
          true,
        )

        // Create Grid of Dates

        this.tbodyNode.innerHTML = ''
        for (let i = 0; i < 6; i += 1) {
          const row = this.tbodyNode.insertRow(i)
          this.lastRowNode = row
          for (let j = 0; j < this.daysOfWeek; j += 1) {
            const cell = document.createElement('td')

            cell.addEventListener('keydown', this.handleDayKeyDown.bind(this))
            cell.addEventListener('focus', this.handleDayFocus.bind(this))

            row.appendChild(cell)
            this.days.push(cell)
          }
        }

        this.updateGrid()
        this.close(false)
        this.setDateForButtonLabel()
      },
      updateGrid() {
        this.$emit('monthChanged', moment(this.focusDay).format('YYYY-MM-DD'))
        if (this.availableDates) {
          this.oldAvailableDates = new Set([...this.oldAvailableDates, ...this.availableDates])
        }

        let flag
        const fd = this.focusDay

        this.monthYearNode.textContent = `${this.monthLabels[fd.getMonth()]} ${fd.getFullYear()}`

        const firstDayOfMonth = new Date(fd.getFullYear(), fd.getMonth(), 1)
        const dayOfWeek = firstDayOfMonth.getDay()

        firstDayOfMonth.setDate(firstDayOfMonth.getDate() - dayOfWeek)

        const d = new Date(firstDayOfMonth)

        let daysNoDisplayed = 0
        for (let i = 0; i < this.days.length; i += 1) {
          flag = d.getMonth() != fd.getMonth()
          this.updateDate(this.days[i], flag, d, this.isSameDay(d, this.selectedDay))
          d.setDate(d.getDate() + 1)
          if (i > this.daysOfWeek) {
            if (flag) {
              daysNoDisplayed += 1
            }
          }
        }
        // Hide last row if all dates are disabled (e.g. in next month)
        if (daysNoDisplayed >= this.daysOfWeek) {
          this.lastRowNode.style.display = 'none'
        } else {
          this.lastRowNode.style.display = 'revert'
          this.lastRowNode.style.visibility = 'visible'
        }
      },
      isSameDay(day1, day2) {
        return day1.getFullYear() == day2.getFullYear()
        && day1.getMonth() == day2.getMonth()
        && day1.getDate() == day2.getDate()
      },
      isNotSameDay(day1, day2) {
        return day1.getFullYear() != day2.getFullYear()
        || day1.getMonth() != day2.getMonth()
      },
      updateDate(domNode, disable, day, selected) {
        let d = day.getDate().toString()
        if (day.getDate() <= 9) {
          d = `0${d}`
        }

        let m = day.getMonth() + 1
        if (day.getMonth() < 9) {
          m = `0${m}`
        }

        domNode.tabIndex = -1
        domNode.removeAttribute('aria-selected')
        const dataDate = `${day.getFullYear()}-${m}-${d}`
        domNode.setAttribute('data-date', dataDate)

        if (disable) {
          domNode.classList.add('disabled')
          domNode.textContent = ''
        } else {
          domNode.classList.remove('disabled')
          domNode.textContent = day.getDate()
          domNode.classList.add('day-not-available')

          if (this.disabledDates.length === 0 || this.oldAvailableDates.has(dataDate)) {
            domNode.classList.remove('day-not-available')
            domNode.addEventListener('click', this.handleDayClickBind)
            domNode.addEventListener('keydown', this.handleDayKeyDownEnterBind)
          } else {
            domNode.removeEventListener('click', this.handleDayClickBind)
            domNode.removeEventListener('keydown', this.handleDayKeyDownEnterBind)
          }

          if (dataDate > this.maxDate) {
            domNode.classList.add('day-not-available')
            domNode.removeEventListener('click', this.handleDayClickBind)
            domNode.removeEventListener('keydown', this.handleDayKeyDownEnterBind)
          }
          if (selected) {
            domNode.setAttribute('aria-selected', 'true')
            domNode.tabIndex = 0
          }
        }
      },
      moveFocusToDay(day) {
        const d = this.focusDay

        this.focusDay = day

        if (
          d.getMonth() != this.focusDay.getMonth()
          || d.getYear() != this.focusDay.getYear()
        ) {
          this.updateGrid()
        }
        this.setFocusDay()
      },
      setFocusDay(flag) {
        let newflag = flag
        if (typeof flag !== 'boolean') {
          newflag = true
        }
        for (let i = 0; i < this.days.length; i += 1) {
          const dayNode = this.days[i]
          const day = this.getDayFromDataDateAttribute(dayNode)

          dayNode.tabIndex = -1
          if (this.isSameDay(day, this.focusDay)) {
            dayNode.tabIndex = 0
            if (newflag) {
              dayNode.focus()
            }
          }
        }
      },
      open() {
        this.dialogNode.style.display = 'block'
        this.dialogNode.style.zIndex = 2

        this.getDateFromTextbox()
        this.updateGrid()
        this.set({ state: 'isStepperContentVisible', value: true })
      },
      isOpen() {
        return window.getComputedStyle(this.dialogNode).display !== 'none'
      },
      close(flag) {
        let newflag = flag
        if (typeof flag !== 'boolean') {
          // Default is to move focus to combobox
          newflag = true
        }

        this.setMessage('')
        this.dialogNode.style.display = 'none'

        if (newflag) {
          this.buttonNode.focus()
        }
        this.set({ state: 'isStepperContentVisible', value: false })
      },
      moveToNextYear() {
        this.focusDay.setFullYear(this.focusDay.getFullYear() + 1)
        this.updateGrid()
      },
      moveToPreviousYear() {
        this.focusDay.setFullYear(this.focusDay.getFullYear() - 1)
        this.updateGrid()
      },
      moveToNextMonth() {
        const nextMonth = this.focusDay.getMonth() + 1
        this.focusDay = this.validDateToNextOrPreviousMonth(this.focusDay, nextMonth)

        this.updateGrid()
      },
      moveToPreviousMonth() {
        const previousMonth = this.focusDay.getMonth() - 1
        this.focusDay = this.validDateToNextOrPreviousMonth(this.focusDay, previousMonth)

        this.updateGrid()
      },
      validDateToNextOrPreviousMonth(date, month) {
        let newDate = new Date(new Date(date).setMonth(month))

        if (newDate.getMonth() > month && !this.isChangeOfYear(newDate.getMonth(), month)) {
          newDate = new Date(date.getFullYear(), month + 1, 0)
        }

        return newDate
      },
      isChangeOfYear(monthA, monthB) {
        return (monthA === this.lastMonth && monthB === this.firstMonth - 1)
          || (monthB === this.lastMonth && monthA === this.firstMonth - 1)
      },
      moveFocusToNextDay() {
        const d = new Date(this.focusDay)
        d.setDate(d.getDate() + 1)
        this.moveFocusToDay(d)
      },
      moveFocusToNextWeek() {
        const d = new Date(this.focusDay)
        d.setDate(d.getDate() + this.daysOfWeek)
        this.moveFocusToDay(d)
      },
      moveFocusToPreviousDay() {
        const d = new Date(this.focusDay)
        d.setDate(d.getDate() - 1)
        this.moveFocusToDay(d)
      },
      moveFocusToPreviousWeek() {
        const d = new Date(this.focusDay)
        d.setDate(d.getDate() - this.daysOfWeek)
        this.moveFocusToDay(d)
      },
      moveFocusToFirstDayOfWeek() {
        const d = new Date(this.focusDay)
        d.setDate(d.getDate() - d.getDay())
        this.moveFocusToDay(d)
      },
      moveFocusToLastDayOfWeek() {
        const d = new Date(this.focusDay)
        d.setDate(d.getDate() + (6 - d.getDay()))
        this.moveFocusToDay(d)
      },
      isDayDisabled(domNode) {
        return domNode.classList.contains('disabled')
      },
      getDayFromDataDateAttribute(domNode) {
        const parts = domNode.getAttribute('data-date').split('-')
        return new Date(parts[0], parseInt(parts[1], 10) - 1, parts[2])
      },
      setTextboxDate(domNode) {
        let d = this.focusDay

        if (domNode) {
          d = this.getDayFromDataDateAttribute(domNode)
          // updated aria-selected
          this.days.forEach(day => (day === domNode
              ? day.setAttribute('aria-selected', 'true')
              : day.removeAttribute('aria-selected')))
        }
        this.textboxNode.value = `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
        this.setDateForButtonLabel()
      },
      getDateFromTextbox() {
        const parts = this.textboxNode.value.split('/')
        const day = parseInt(parts[0], 10)
        const month = parseInt(parts[1], 10)
        let year = parseInt(parts[2], 10)

        if (
          parts.length === 3
          && Number.isInteger(month)
          && Number.isInteger(day)
          && Number.isInteger(year)
        ) {
          if (year < 100) {
            year = 2000 + year
          }
          this.focusDay = new Date(year, month - 1, day)
          this.selectedDay = new Date(this.focusDay)
        } else {
          // If not a valid date (MM/DD/YY) initialize with todays date
          this.focusDay = new Date()
          this.selectedDay = new Date(0, 0, 1)
        }
      },
      setDateForButtonLabel() {
        const parts = this.textboxNode.value.split('/')
        if (
          parts.length === 3
          && Number.isInteger(parseInt(parts[0], 10))
          && Number.isInteger(parseInt(parts[1], 10))
          && Number.isInteger(parseInt(parts[2], 10))
        ) {
          const day = new Date(
            parseInt(parts[2], 10),
            parseInt(parts[1], 10) - 1,
            parseInt(parts[0], 10),
          )

          let label = this.buttonLabelChange
          label += `, ${this.dayLabels[day.getDay()]}`
          label += ` ${this.monthLabels[day.getMonth()]}`
          label += ` ${day.getDate()}`
          label += `, ${day.getFullYear()}`
          this.buttonNode.setAttribute('aria-label', label)
          const m = day.getMonth() < 9 ? `0${day.getMonth() + 1}` : day.getMonth() + 1
          const d = day.getDate() <= 9 ? `0${day.getDate()}` : day.getDate()
          this.model = `${day.getFullYear()}-${m}-${d}`
        } else {
          // If not a valid date, initialize with "Choose Date"
          this.buttonNode.setAttribute('aria-label', this.buttonLabelChoose)
        }
      },
      setMessage(str) {
        // function setMessageDelayed() {
        //   this.messageNode.textContent = str
        // }

        if (str !== this.lastMessage) {
          setTimeout(this.setMessageDelayed(str), 200)
          this.lastMessage = str
        }
      },
      setMessageDelayed(str) {
        this.messageNode.textContent = str
        this.messageNode.setAttribute('aria-label', str)
      },
      handleNextYearButton(event) {
        let flag = false
        switch (event.type) {
          case 'keydown':
            switch (event.key) {
              case 'Esc':
              case 'Escape':
                this.close()
                flag = true
                break

              case 'Enter':
                this.moveToNextYear()
                this.setFocusDay(false)
                flag = true
                break
              default:
                break
            }

            break

          case 'click':
            this.moveToNextYear()
            this.setFocusDay(false)
            break

          default:
            break
        }

        if (flag) {
          event.stopPropagation()
          event.preventDefault()
        }
      },
      handlePreviousYearButton(event) {
        let flag = false

        switch (event.type) {
          case 'keydown':
            switch (event.key) {
              case 'Enter':
                this.moveToPreviousYear()
                this.setFocusDay(false)
                flag = true
                break

              case 'Tab':
                if (event.shiftKey) {
                  this.okButtonNode.focus()
                  flag = true
                }
                break

              case 'Esc':
              case 'Escape':
                this.close()
                flag = true
                break

              default:
                break
            }

            break

          case 'click':
            this.moveToPreviousYear()
            this.setFocusDay(false)
            break

          default:
            break
        }

        if (flag) {
          event.stopPropagation()
          event.preventDefault()
        }
      },
      handleNextMonthButton(event) {
        let flag = false

        switch (event.type) {
          case 'keydown':
            switch (event.key) {
              case 'Esc':
              case 'Escape':
                this.close()
                flag = true
                break

              case 'Enter':
                this.moveToNextMonth()
                this.setFocusDay(false)
                flag = true
                break
              default:
                break
            }

            break

          case 'click':
            this.moveToNextMonth()
            this.setFocusDay(false)
            break

          default:
            break
        }

        if (flag) {
          event.stopPropagation()
          event.preventDefault()
        }
      },
      handlePreviousMonthButton(event) {
        let flag = false

        switch (event.type) {
          case 'keydown':
            switch (event.key) {
              case 'Esc':
              case 'Escape':
                this.close()
                flag = true
                break

              case 'Enter':
                this.moveToPreviousMonth()
                this.setFocusDay(false)
                flag = true
                break
              default:
                break
            }

            break

          case 'click':
            this.moveToPreviousMonth()
            this.setFocusDay(false)
            flag = true
            break

          default:
            break
        }

        if (flag) {
          event.stopPropagation()
          event.preventDefault()
        }
      },
      handleDayKeyDown(event) {
        let flag = false

        switch (event.key) {
          case 'Esc':
          case 'Escape':
            this.close()
            break

          case ' ':
            this.setTextboxDate(event.currentTarget)
            flag = true
            break

          case 'Tab':
            this.cancelButtonNode.focus()
            if (event.shiftKey) {
              this.nextYearNode.focus()
            }
            this.setMessage('')
            flag = true
            break

          case 'Right':
          case 'ArrowRight':
            this.moveFocusToNextDay()
            flag = true
            break

          case 'Left':
          case 'ArrowLeft':
            this.moveFocusToPreviousDay()
            flag = true
            break

          case 'Down':
          case 'ArrowDown':
            this.moveFocusToNextWeek()
            flag = true
            break

          case 'Up':
          case 'ArrowUp':
            this.moveFocusToPreviousWeek()
            flag = true
            break

          case 'PageUp':
            if (event.shiftKey) {
              this.moveToPreviousYear()
            } else {
              this.moveToPreviousMonth()
            }
            this.setFocusDay()
            flag = true
            break

          case 'PageDown':
            if (event.shiftKey) {
              this.moveToNextYear()
            } else {
              this.moveToNextMonth()
            }
            this.setFocusDay()
            flag = true
            break

          case 'Home':
            this.moveFocusToFirstDayOfWeek()
            flag = true
            break

          case 'End':
            this.moveFocusToLastDayOfWeek()
            flag = true
            break
          default:
            break
        }

        if (flag) {
          event.stopPropagation()
          event.preventDefault()
        }
      },
      handleDayKeyDownEnter(event) {
        let flag = false

        switch (event.key) {
          case 'Enter':
            this.setTextboxDate(event.currentTarget)
            this.close()
            flag = true
            break
          default:
            break
        }

        if (flag) {
          event.stopPropagation()
          event.preventDefault()
        }
      },
      handleDayClick(event) {
        if (!this.isDayDisabled(event.currentTarget)) {
          this.setTextboxDate(event.currentTarget)
          this.close()
        }

        event.stopPropagation()
        event.preventDefault()
      },
      handleDayFocus() {
        this.setMessage(this.messageCursorKeys)
      },
      handleButtonKeydown(event) {
        if (event.key === 'Enter' || event.key === ' ') {
          this.open()
          this.setFocusDay()

          event.stopPropagation()
          event.preventDefault()
        }
      },
      handleButtonClick(event) {
        if (this.isOpen()) {
          this.close()
        } else {
          this.open()
          this.setFocusDay()
        }

        event.stopPropagation()
        event.preventDefault()
      },
      handleBackgroundMouseUp(event) {
        if (
          !this.buttonNode.contains(event.target)
          && !this.dialogNode.contains(event.target)
        ) {
          if (this.isOpen()) {
            this.close(false)
            event.stopPropagation()
            event.preventDefault()
          }
        }
      },
    },

    computed: {
      //
    },

    slug: 'component.forms.calendar-picker-group.calendar-picker',
  }
</script>

<style lang="scss">
  .sr-only {
    position: absolute;
    top: -2000em;
    left: -3000em;
  }

  .datepicker {
    margin-top: 1em;
    position: relative;
    font-family: Averta;
    z-index: 10;

    .group {
      display: flex;
      position: relative;
      align-items: center;

      input {
        &:disabled {
          background: rgba(100, 100, 100, 0.3);
        }
        height: 3rem;
      }

      button {
        &:disabled {
          cursor: auto;
        }
        .tracking-datepicker__calendar {
          display: flex;
          width: 3rem;
          height: 3rem;
          margin: 0 0.4rem 0 0;

          svg {
            width: 3rem;
            height: 3rem;
            fill: color(dark-primary);
          }
        }
      }

      label {
        display: flex;
        margin-bottom: 0.5rem;
      }

      .desc {
        position: absolute;
        left: 0;
        top: 2em;
      }

      .fa-calendar-alt {
        color: hsl(216, 89%, 51%);
      }

    }
    .datepicker-dialog {
      position: absolute;
      max-width: 320px;
      min-width: 260px;
      width: 75vw;
      clear: both;
      margin-top: 0.15em;
      border-radius: 10px;
      padding: 0;
      background-color: color(_white);
      box-shadow: box-shadow(default);

      .datepicker-header {
        cursor: default;
        background-color: color(_white);
        padding: 10px;
        font-weight: bold;
        text-transform: uppercase;
        color: color(dark-primary) !important;
        display: flex;
        align-items: center;
        justify-content: space-around;
        box-shadow: box-shadow(default);
        border-radius: 10px 10px 0 0;

        &::before {
            content: none;
        }

        h2 {
          margin: 0;
          padding: 0;
          display: inline-block;
          font-size: 1em;
          color: color(dark-primary) !important;
          text-transform: none;
          font-weight: bold;
        }

        button {
          border-style: none;
          background: transparent;

          &::-moz-focus-inner {
            border: 0;
          }
        }

        .prev-year,
        .prev-month,
        .next-month,
        .next-year {
          padding: 4px;
          width: 25px;
          height: 25px;
          color: color(_white);
          background-color: color(button-background) !important;
          border-radius: 100px;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: center;

          &:focus {
            padding: 2px;
            border: 2px solid color(_white);
            outline: 0;
          }

          &:hover {
            padding: 3px;
            border: 1px solid color(_white);
          }
        }

        .month-year {
          display: inline-block;
          width: 12em;
          text-align: center;
        }
      }

      table.dates {
        max-width: 320px;
        min-width: 260px;
        width: 75vw;
        margin-bottom: 0;
        padding-left: 1em;
        padding-right: 1em;
        padding-bottom: 1em;

        th,td {
          text-align: center;
          border: none;
          padding-right: 0;
          padding-left: 0;
        }
        tr {
          border: 1px solid color(dark-primary);
          td {
            padding: 3px;
            margin: 0;
            line-height: inherit;
            width: 41px;
            height: 41px;
            font-size: 15px;
            border: none;
            vertical-align: middle;
            cursor: pointer;
            @media (max-width: 430px), (max-height: 300px) {
              height: 10vw;
            }
            &.disabled {
              padding: 2px;
              border: none;
              width: 41px;
              height: 41px;
              cursor: auto;
              @media (max-width: 430px), (max-height: 300px) {
                height: 10vw;
            }
            }

            &:not(.disabled) {
              &:not(.day-not-available):hover {
                padding: 2px;
                border: 1px solid rgb(100, 100, 100);
              }
              &.day-not-available {
                background: rgba(100, 100, 100, 0.3);
                border-radius: 100px;
                cursor: auto;
              }
            }

            &:focus, &:hover {
              padding: 0;
              background-color: hsl(216, 80%, 92%);
            }

            &:focus {
              padding: 1px;
              outline: 0;
            }

            &[aria-selected] {
              padding: 1px;
              &:focus {
                padding: 1px;
              }
            }

            &[tabindex="0"] {
              background-color: color(button-background) !important;
              color: color(_white);
              border-radius: 100px;
            }
          }
        }
      }

      .dialog-message {
        padding-top: 0.25em;
        padding-bottom: 0.25em;
        padding-left: 1em;
        height: 2em;
        background-color: color(button-background) !important;
        color: color(_white);
        border-radius: 0 0 10px 10px;
      }
    }
  }

</style>
