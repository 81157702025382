<template>
  <div
    class="base-checkbox"
    :class="{
      'base-checkbox--has-tooltip': hasTooltip,
      'base-checkbox--has-error': hasErrors,
      'base-checkbox--is-multi': isMulti,
    }"
    :data-testid="`${dataTestid} base-checkbox`"
  >
    <div
      class="base-checkbox__container nhsuk-form-group input-group"
      role="checkbox"
      tabindex="0"
      :aria-checked="`${isChecked}`"
      :aria-describedby="ariaDescribedby"
      :aria-invalid="hasErrors"
      :aria-required="validation.checkbox_required"
      @keydown.prevent.enter.space="onChange"
    >
      <label
        class="base-checkbox__label nhsuk-label--m nhsuk-u-margin-bottom-2 nhsuk-u-font-size-22"
        data-testid="base-checkbox-label"
        :for="inputId"
      >
        <input
          class="base-checkbox__input"
          data-testid="base-checkbox-input"
          type="checkbox"
          :id="inputId"
          :value="inputValue"
          v-bind="{ name }"
          v-model="state"
        >

        <span class="base-checkbox__check"/>

        <span class="base-checkbox__container">
          <slot
            name="option"
            v-bind="{ label }"
          >
            <span
              v-if="label"
              class="base-radio__container"
              v-html="$t(label)"
            />
          </slot>
        </span>
      </label>

      <slot/>
    </div>

    <tooltip
      v-if="hasTooltip"
      :message="tooltip"
    />
  </div>
</template>

<script>
  import Tooltip from '@/components/tooltip/Tooltip'

  import addVModel from '@/mixins/addVModel'
  import testid from '@/mixins/testid/testid'

  export default {
    components: {
      Tooltip,
    },

    mixins: [
      addVModel('state'),
      testid(),
    ],

    props: {
      hasErrors: {
        type: Boolean,
        default: false,
      },
      descriptionId: {
        type: [String, Boolean],
        default: false,
      },
      inputValue: {
        type: [Boolean, Number, String],
        required: false,
        default: false,
      },
      isMulti: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        required: false,
        default: '',
      },
      name: {
        type: String,
        required: true,
      },
      tooltip: {
        type: String,
        required: false,
        default: '',
      },
      validation: {
        type: Object,
        required: false,
        default: () => ({}),
      },
    },

    methods: {
      onChange() {
        if (Array.isArray(this.value)) {
          const newState = [...this.value]

          newState.includes(this.inputValue)
            ? newState.splice(newState.indexOf(this.inputValue), 1)
            : newState.push(this.inputValue)

          this.state = newState
        } else {
          this.state = !this.state
        }
      },
    },

    computed: {
      hasTooltip() {
        return this.tooltip.length
      },
      isChecked() {
        return !!(Array.isArray(this.value)
          ? this.value.includes(this.inputValue)
          : this.state)
      },
      ariaDescribedby() {
        return this.descriptionId || undefined
      },
      inputId() {
        return `${this.name}-${this.uuid}`
      },
    },
  }
</script>

<style lang="scss">
  .base-checkbox {
    margin: 1.8rem 0 0 0;
    position: relative;
    &--has-tooltip {
      display: flex;
      align-items: center;
      .popper-container {
        margin-left: 0.5rem;
      }
      .tooltip-toggle {
        padding: 0;
      }
    }
    &--has-error {
      .base-checkbox__label {
        color: color(error);
      }
      .base-checkbox__check {
        border-color: color(error);
      }
    }
    &--is-multi {
      margin: 0.8rem 0 0;
    }
    &__label {
      display: flex;
      align-items: center;
      cursor: pointer;
      a, button {
        color: color(dark-primary);
        text-decoration: underline;
      }
      button {
        padding: 0 0.5rem;
      }
    }
    &__input {
      width: 0;
      height: 0;
      position: absolute;
      left: 0;
      z-index: z-index(under-base);
      cursor: pointer;
      opacity: 0;
      overflow: hidden;
      pointer-events: none;
      visibility: hidden;
      &:checked + .base-checkbox__check {
        border-width: 2px;
        &::before {
          animation: rightcheck 0.2s ease 0s forwards;
        }
        &::after {
          animation: leftcheck 0.1s ease 0s forwards;
        }
      }
    }
    &__check {
      display: inline-block;
      flex-shrink: 0;
      width: 2rem;
      height: 2rem;
      margin-right: 1rem;
      position: relative;
      z-index: z-index(base);
      border: 1px solid color(input-border);
      transition: border-color .3s cubic-bezier(0, 0.8, 0.5, 1);
      &::before,
      &::after {
        content: '';
        width: 2px;
        height: 0;
        position: absolute;
        background-color: color(input-border);
        border-radius: .5rem;
        transform-origin: left top;
      }
      &::before {
        top: 1.1rem;
        left: .8rem;
        transform: rotate(-140deg);
      }
      &::after {
        top: .9rem;
        left: .4rem;
        transform: rotate(-45deg);
      }
    }
    &-container {
      width: 100%;
    }
    @keyframes leftcheck {
      0% { height: 0; }
      100% { height: .4rem; }
    }
    @keyframes rightcheck {
      0% { height: 0; }
      50% { height: 0; }
      100% { height: .6rem; }
    }
  }
</style>
