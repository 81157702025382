<template>
  <div class="plugin-double-line-chart">
    <line-chart
      ref="chart"
      :chartData="chart.data"
      :height="220"
      :options="chart.options"
    />
  </div>
</template>

<script>
  import Chart from 'chart.js'
  import moment from 'moment'
  import { mapGetters, mapState } from 'vuex'

  import LineChart from '@/components/charts/line-chart/LineChart'

  import cssColorVariableToRgba from '@/helpers/cssColorVariableToRgba'

  import eventBus from '@/tools/event-bus'

  Chart.pluginService.register({
    afterUpdate(chart) {
      const xScale = chart.scales['x-axis-0']

      if (!xScale.options.ticks.center) return

      const { left, width, ticks, options, top } = xScale
      const { fontColor, fontSize, fontStyle, fontFamily } = options.ticks
      const {
        defaultFontColor,
        defaultFontSize,
        defaultFontStyle,
        defaultFontFamily,
      } = Chart.defaults.global
      const { helpers: { each, fontString, getValueOrDefault } } = Chart
      const tickFontColor = getValueOrDefault(fontColor, defaultFontColor)
      const tickFontSize = getValueOrDefault(fontSize, defaultFontSize)
      const tickFontStyle = getValueOrDefault(fontStyle, defaultFontStyle)
      const tickFontFamily = getValueOrDefault(fontFamily, defaultFontFamily)

      xScale.draw = function draw() {
        each(ticks, function fn(value, index) {
          const { ctx, options: { position } } = this
          const segmentWidth = width / ticks.length
          const tickPosX = left + ((index + 1) * segmentWidth) - (segmentWidth / 2)
          const tickPosY = isTop ? options.ticks.padding + 46 : top + options.ticks.padding + 20
          const isTop = position === 'top'

          if (options.ticks.display) {
            ctx.save()
            ctx.translate(tickPosX, tickPosY)
            ctx.font = fontString(tickFontSize, tickFontStyle, tickFontFamily)
            ctx.textAlign = 'center'
            ctx.textBaseline = isTop ? 'bottom' : 'top'
            ctx.fillStyle = tickFontColor
            ctx.fillText(value, 0, 0)
            ctx.restore()
          }
        }, xScale)
      }
    },
    afterDatasetDraw(chart) {
      chart.config.data.datasets.forEach((dataset, idx) => {
        if (!dataset.borderDisabled) return

        const meta = chart.getDatasetMeta(idx)

        meta.data = meta.data.map(item => {
          item._view.borderColor = 'rgba(0,0,0,0)'

          return item
        })
      })
    },
  })

  export default {
    components: {
      LineChart,
    },

    props: {
      annotationLabel: {
        type: String,
        required: false,
        default: '',
      },
      onClick: {
        type: Function,
        required: false,
        default: () => {},
      },
      chartData: {
        type: [Array, Object],
        required: false,
        default: null,
        validator(val) {
          return Array.isArray(val) ? val.length <= 2 : true
        },
      },
      from: {
        type: String,
        required: false,
        default: null,
      },
      goal: {
        type: Number,
        required: false,
        default: 0,
      },
      goalPadding: {
        type: Number,
        required: false,
        default: 0,
      },
      period: {
        type: String,
        required: false,
        default: '',
      },
      suggestedMax: {
        type: Number,
        required: false,
        default: 0,
      },
      suggestedMin: {
        type: Number,
        required: false,
        default: 0,
      },
      to: {
        type: String,
        required: false,
        default: null,
      },
    },

    created() {
      this.updateResponsiveOptions()
    },

    watch: {
      chartData: {
        handler() {
          this.processChartData()
        },
        immediate: true,
      },
      isMinMd() {
        this.updateResponsiveOptions()
      },
    },

    data() {
      return {
        pointChecked: {
          datasetIndex: null,
          index: null,
        },
        chart: {
          data: {},
          options: {
            elements: {
              line: {
                borderWidth: 2,
              },
              point: {
                borderColor: '#00ff00',
                backgroundColor: '#00ff00',
                borderWidth: 3,
                hoverBorderWidth: 3,
                radius: 8,
                hoverRadius: 8,
              },
            },
            animation: false,
            annotation: false,
            hover: {
              onHover: (e, { length }) => {
                e.target.style.cursor = length ? 'pointer' : 'default'
              },
              mode: 'point',
              intersect: true,
              axis: 'xy',
              animationDuration: 0,
            },
            layout: {
              padding: {
                top: 20,
                bottom: 20,
                left: 0,
                right: 0,
              },
            },
            legend: {
              display: false,
            },
            maintainAspectRatio: false,
            responsive: true,
            scales: {
              xAxes: [{
                type: 'time',
                position: 'top',
                gridLines: {
                  display: false,
                  offsetGridLines: false,
                },
                ticks: {
                  maxRotation: 0,
                  mode: 'series',
                  padding: 0,
                  center: true,
                  fontColor: cssColorVariableToRgba('dark-grey'),
                  callback: (value, index, values) => {
                    if (this.period === 'day' && values.length === index + 1) {
                      return '23:59pm'
                    }

                    return value
                  },
                },
                time: {
                  displayFormats: {
                    hour: this.period === 'day' ? 'H:mm' : 'DD.MM',
                  },
                  distribution: 'linear',
                  isoWeekday: true,
                  max: moment.utc(this.to).add(...(this.period === 'day'
                    ? [this.getTimezoneOffsetNumber, 'h'] : [0, 'd']
                  )),
                  min: moment.utc(this.from).add(...(this.period === 'day'
                    ? [-2 + this.getTimezoneOffsetNumber, 'h'] : [-1, 'd']
                  )),
                  minUnit: 'minute',
                  round: 'minute',
                  source: 'auto',
                  stepSize: this.period === 'day'
                    ? this.isMinMd
                      ? 2
                      : 4
                    : this.period === 'month'
                      ? this.isMinMd
                        ? 48
                        : 120
                      : this.isMinMd
                        ? 24
                        : 48,
                  unit: 'hour',
                  parser: val => {
                    if (moment.isMoment(val)) return val

                    const offset = this.period === 'day' ? [-1 + this.getTimezoneOffsetNumber, 'h'] : [0, 'h']
                    const date = moment.parseZone(val).add(...offset)

                    return date
                  },
                },
              }],
              yAxes: [{
                position: 'left',
                type: 'linear',
                id: 'first-y-axis',
                fontColor: cssColorVariableToRgba('dark-grey'),
                gridLines: {
                  drawBorder: false,
                  drawTicks: false,
                  zeroLineColor: '#e5e5e5',
                },
                ticks: {
                  beginAtZero: true,
                  fontColor: cssColorVariableToRgba('dark-grey'),
                  maxTicksLimit: 6,
                  padding: this.isMinMd ? 20 : 10,
                  suggestedMax: Math.max(this.goal + this.goalPadding, this.suggestedMax),
                  suggestedMin: Math.min(this.goal, this.suggestedMin),
                },
              }, {
                position: 'right',
                type: 'linear',
                id: 'second-y-axis',
                gridLines: false,
                fontColor: cssColorVariableToRgba('chart5'),
                ticks: {
                  beginAtZero: true,
                  fontColor: cssColorVariableToRgba('chart5'),
                  maxTicksLimit: 6,
                  padding: this.isMinMd ? 20 : 10,
                  suggestedMax: Math.max(this.goal + this.goalPadding, this.suggestedMax),
                  suggestedMin: Math.min(this.goal, this.suggestedMin),
                },
              }],
            },
            tooltips: false,
          },
        },
      }
    },

    methods: {
      processChartData() {
        this.chart.data = { datasets: [this.dataset1, this.dataset2] }
        this.chart.options.onClick = (e, activeElements) => {
          const { _datasetIndex: datasetIndex, _index: index } = activeElements[0]
          this.pointChecked = {
            datasetIndex,
            index,
          }
          this.onClick(this.$children[0].$data._chart.getElementAtEvent(e)?.[0])
        }
      },
      updateResponsiveOptions() {
        this.chart.options.scales.xAxes[0].time.stepSize = this.period === 'day'
          ? this.isMinMd
            ? 2
            : 4
          : this.period === 'month'
            ? this.isMinMd
              ? 48
              : 120
            : this.isMinMd
              ? 24
              : 48
        this.chart.options.scales.xAxes[0].time.max = moment.utc(this.to).add(...(this.period === 'day'
          ? [this.getTimezoneOffsetNumber, 'h'] : [0, 'd']
        ))
        this.chart.options.scales.xAxes[0].time.min = moment.utc(this.from).add(...(this.period === 'day'
          ? [-2 + this.getTimezoneOffsetNumber, 'h'] : [-1, 'd']
        ))
        eventBus.$emit('lineChart/refreshChart')
      },
      constructDataset({ chartData, borderColor, label, yAxisID }) {
        return {
          borderColor,
          label,
          yAxisID,
          fill: false,
          lineTension: 0,
          data: chartData?.label?.map((val, idx) => ({
            x: val,
            y: chartData?.value?.[idx],
          })),
          backgroundColor: chartData?.backgroundColor,
          pointBorderColor: context => {
            const { dataIndex, datasetIndex } = context
            const { index: indexChecked, datasetIndex: datasetIndexChecked } = this.pointChecked
            if (dataIndex === indexChecked && datasetIndex === datasetIndexChecked) {
              return 'rgb(211 159 42)'
            }
            return chartData?.backgroundColor
          },
          borderDisabled: false,
        }
      },
    },

    computed: {
      ...mapState('layout', [
        'wW',
      ]),
      ...mapGetters('colors', [
        'colors',
      ]),
      ...mapGetters('user', [
        'getTimezoneOffsetNumber',
      ]),
      isMinMd() {
        return this.wW ? this.wW > 768 : true
      },
      dataset1() {
        return this.constructDataset({
          chartData: this.chartData?.[0],
          borderColor: this.colors['color-chart-glucose'],
          label: 's1',
          yAxisID: 'first-y-axis',
        })
      },
      dataset2() {
        return this.constructDataset({
          chartData: this.chartData?.[1],
          borderColor: this.colors['color-chart-hba1c'],
          label: 's2',
          yAxisID: 'second-y-axis',
        })
      },
      timeUnit() {
        return this.period === 'day' ? 'hour' : 'day'
      },
    },
  }
</script>
